import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dailyRewardImg from '../../../../../assets/img/metaberry_ui/daily-reward2.svg'
import coinImg from '../../../../../assets/img/metaberry_ui/coin.svg'
import chronometerImg from '../../../../../assets/img/metaberry_ui/chronometer.svg'
import './MissionDailyRewardCard.scss'
import translate from '../../../../../i18n/translate'
import { getMissionDailyRewardGameEngine } from '../../../../../services/rewardsService'
import { Analysis } from '../../../../../services/analysisService'
import {
  setCanGetDailyReward,
  setCoinsAccumulated,
  setDailyAccessReward
} from '../../../../../store/RewardsSlice/RewardsSlice'
import { store } from '../../../../../store/configureStore'
import circleBackground from '../../../../../assets/img/metaberry_ui/circle-background.svg'

const MissionDailyRewardCard = ({ onClickGetReward }) => {
  const coinsAccumulated = useSelector((s) => s.rewards.coinsAccumulated)
  const [leftHours, setLeftHours] = useState(0)
  const [leftMinutes, setLeftMinutes] = useState(0)
  const [leftTimeBarWidth, setLeftTimeBarWidth] = useState(0)
  const canGetDailyReward = useSelector(
    (state) => state.rewards.canGetDailyReward
  )
  const nextDailyReward = useSelector((state) => state.rewards.nextDailyReward)
  const amountDailyReward = useSelector(
    (state) => state.rewards.amountDailyReward
  )
  const [cooldownHours, setCooldownHours] = useState(24)
  const [isLoading, setIsLoading] = useState(false)
  const LoadingImage = `${process.env.PUBLIC_URL}/assets/graphics/images/loading.svg`

  useEffect(() => {
    const dailyRewardCooldownHours = store.getState()?.rewards?.cooldownHours
    if (dailyRewardCooldownHours) {
      setCooldownHours(dailyRewardCooldownHours)
    }

    calculateTimeLeft()

    const leftTimer = setInterval(() => {
      calculateTimeLeft()
    }, 60000)

    document.addEventListener('visibilitychange', onVisibilitychange)

    return () => {
      document.removeEventListener('visibilitychange', onVisibilitychange)
      clearInterval(leftTimer)
    }
  }, [canGetDailyReward, nextDailyReward])

  const onVisibilitychange = () => {
    if (document.visibilityState === 'visible') {
      calculateTimeLeft()
    }
  }

  const extractHoursAndMinutes = (milliseconds) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60))
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))

    return { hours, minutes }
  }

  const calculateTimeLeftPercentage = (milliseconds) => {
    const MILLIS_IN_COOLDOWN = cooldownHours * 60 * 60 * 1000

    let percentage = Math.round((milliseconds / MILLIS_IN_COOLDOWN) * 100)

    if (percentage < 0) percentage = 0
    if (percentage > 100) percentage = 100

    percentage = 100 - percentage

    return percentage
  }

  const calculateTimeLeft = () => {
    if (nextDailyReward && !canGetDailyReward) {
      const millisecondsLeft = new Date(nextDailyReward) - new Date()
      if (millisecondsLeft <= 0) {
        store.dispatch(setCanGetDailyReward(true))
        return
      }

      const { hours, minutes } = extractHoursAndMinutes(millisecondsLeft)
      setLeftTimeBarWidth(calculateTimeLeftPercentage(millisecondsLeft) + '%')
      setLeftHours(hours)
      setLeftMinutes(minutes)
    }
  }

  const handleGetDailyReward = async () => {
    setIsLoading(true)
    onClickGetReward()

    try {
      const missionDailyRewardResponse = await getMissionDailyRewardGameEngine()
      const wasRewardClaimed =
        !missionDailyRewardResponse?.data?.error &&
        missionDailyRewardResponse?.data?.userClaimableReward
      const nextClaimTime = missionDailyRewardResponse?.data?.nextClaimTime

      if (nextClaimTime) {
        if (wasRewardClaimed) {
          const coinsAfterReward = coinsAccumulated + amountDailyReward

          Analysis.sendSegmentTrackEvent(
            Analysis.SEGMENT_EVENTS['Daily Reward Collected'],
            {
              coins_earned: amountDailyReward,
              coins_acumulated: coinsAfterReward
            }
          )

          store.dispatch(setCoinsAccumulated(coinsAfterReward))
        }
        // Actualizar los valores que cambian para 'daily reward'
        store.dispatch(
          setDailyAccessReward({
            canGetDailyReward: false,
            nextDailyReward: nextClaimTime,
            amountDailyReward: amountDailyReward,
            cooldownHours: cooldownHours
          })
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="mission-daily-reward-card">
      <div className="mission-daily-reward_circle-background">
        <img src={circleBackground} alt="" />
      </div>
      <div className="mission-daily-reward-card_image">
        <img src={dailyRewardImg} alt="" />
        {/* <span>{'+' + amountDailyReward}</span> */}
        <span>{'+1'}</span>
      </div>
      <div className="mission-daily-reward-card_information">
        <div className="mission-daily-reward-card_title">
          {translate(
            canGetDailyReward
              ? 'missions_daily_reward_title-can-get'
              : 'missions_daily_reward_title-cant-get'
          )}
        </div>
        <div className="mission-daily-reward-card_message">
          {translate(
            canGetDailyReward
              ? 'missions_daily_reward_message'
              : 'missions_daily_reward_next_message'
          )}
        </div>
      </div>

      {canGetDailyReward && (
        <div className="mission-daily-reward-card_actions">
          <div
            className="mission-daily-reward-card_get-button"
            onClick={handleGetDailyReward}
          >
            {isLoading ? (
              <img src={LoadingImage} alt="Loading" />
            ) : (
              translate('missions_daily_reward_get_button')
            )}
          </div>
        </div>
      )}

      {!canGetDailyReward && (
        <>
          <div className="missions_daily_reward_timer">
            <img
              className="missions_daily_reward_timer-chronometer"
              src={chronometerImg}
              alt=""
            />

            <div className="missions_daily_reward_timer-bar">
              <div
                className="missions_daily_reward_timer-bar-inner"
                style={{ width: leftTimeBarWidth }}
              ></div>
            </div>

            <img
              className="missions_daily_reward_timer-coin"
              src={coinImg}
              alt=""
            />
          </div>
          <div className="missions_daily_reward_timer-message">
            {translate('missions_daily_reward_time-left', {
              hours: leftHours,
              minutes: leftMinutes
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default MissionDailyRewardCard
