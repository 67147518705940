import {
  fetchWithToken,
  createEndPoint,
  getToken
} from '../../../../services/util'

const userMaps = {}

export const getMapData = async (courseGuid, unitGuid) => {
  const userToken = getToken()

  // Clear maps if token changed
  if (Object.keys(userMaps).length > 0 && !userMaps[userToken]) {
    Object.keys(userMaps).forEach((key) => delete userMaps[key])
  }

  // Generate cache key
  const cacheKey = unitGuid || 'course'

  // Return cached data if exists
  if (userMaps?.[userToken]?.[cacheKey]) {
    return userMaps[userToken][cacheKey]
  }

  // Fetch new data if not cached
  const mapJsonPoint = createEndPoint(
    unitGuid
      ? `lms/courses/${courseGuid}/blueberry/units/${unitGuid}/phaser`
      : `lms/courses/${courseGuid}/blueberry/phaser`
  )

  const data = await fetchWithToken(mapJsonPoint)
    .then((json) => {
      // Cache successful responses only
      if (!userMaps[userToken]) {
        userMaps[userToken] = {}
      }

      userMaps[userToken][cacheKey] = json
      return json
    })
    .catch((error) => {
      console.error('getMapData error', error)
      return { error: error }
    })

  return data
}

const getSpawnValueByIndex = (mapData, index) => {
  const interactivesLayer = mapData?.layers?.find(
    (layer) => layer.name === 'interactives'
  )
  const objects = interactivesLayer?.objects
  if (!objects) return null

  const unit = objects.find((obj) => {
    if (obj.name !== 'unit') return false

    const indexProp = obj.properties?.find((prop) => prop.name === 'index')
    if (!indexProp || indexProp.value !== index) return false

    const spawnProp = obj.properties?.find((prop) => prop.name === 'spawn')
    if (!spawnProp) return false

    return true
  })

  if (!unit) return null

  const spawnProp = unit.properties.find((prop) => prop.name === 'spawn')
  return spawnProp?.value || null
}

export const getEntranceTile = async (courseGuid, unitIndex) => {
  const courseMapData = await getMapData(courseGuid)
  return getSpawnValueByIndex(courseMapData, unitIndex)
}
