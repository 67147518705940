import React, { useEffect, useState } from 'react'
import PracticeView from './modules/views/PracticeView/PracticeView'
import PracticeCompleteView from './modules/views/PracticeCompleteView/PracticeCompleteView'
import {
  setAppHostReactNative,
  setAppTokenReactNative
} from './services/practiceServicesReactNative'
import { store } from '../../store/configureStore'
import { updateDevMode } from '../../store/Configuration/Configuration'
import { setCustomLanguageCode, initLang } from '../../i18n/initI18n'
import ActivityLoading from './modules/components/ActivityLoading/ActivityLoading'
import translate from '../../i18n/translate'
import { useGamificationActivityContext } from '../../context/GamificationActivityContext/useGamificationActivityContext'
import { CURRENCY_TYPES } from '../../config/constants/GamificationConstants'
import { setCourse } from '../../store/Course/Course'

const AppPracticePage = () => {
  const PRACTICE_STATES = ['LOADING', 'PRACTICING', 'COMPLETED']
  const [practiceState, setPracticeState] = useState(0)
  const [practiceBaseData, setPracticeBaseData] = useState(null)
  const { pointsReward, currencyReward, setResetActivityGamification } =
    useGamificationActivityContext()
  const [practiceProgressData, setPracticeProgressData] = useState({
    sessionId: undefined,
    earnedExperience: pointsReward,
    earnedExtraExperience: 0,
    earnedPieces: [0, 0, 0],
    earnedCoins: 0
  })
  const [isIphone, setIsIphone] = useState(false)

  useEffect(() => {
    setPracticeProgressData({
      ...practiceProgressData,
      earnedExperience: pointsReward
    })
  }, [pointsReward])

  useEffect(() => {
    setPracticeProgressData({
      ...practiceProgressData,
      earnedCoins: currencyReward[CURRENCY_TYPES.COINS]
    })
  }, [currencyReward[CURRENCY_TYPES.COINS]])

  useEffect(() => {
    setIsIphone(/iPad|iPhone|iPod/.test(window.navigator.userAgent))

    window.addEventListener('message', messageFromReactNative)
    document.addEventListener('message', messageFromReactNative)

    messageToReactNative({ function: 'onReady' })

    // Pruebas local ------------------------------ (inicio)
    /* eslint-disable */
    if (false) {
      setTimeout(async () => {
        // QA
        /*
        const appToken =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJndWlkIjoiNGUwYmIwYjAtYzE4YS0xMWVkLTgyYzItZWJhMDMyMzVlODQ5IiwidXNlcm5hbWUiOiJzdHVkZW50LTAxLTAzLWVzIiwiZW1haWwiOiJzdHVkZW50LTAxLTAzLWVzIiwicm9sZV9ndWlkIjoiUjAxIiwibmFtZSI6IlN0dWRlbnQgQyIsImxhc3RuYW1lIjoiT25lQ2xpY2siLCJhdmF0YXIiOiIiLCJsYW5nX2lkIjpudWxsLCJ0ZW5hbnRfdXJsIjoiaHR0cHM6Ly9ibHVlYmVycnkudGFuZ2VyaW5lLXFhMS5vbmVjbGlja2xhYnMuZXMvYXBpLyIsInRlbmFudF9ndWlkIjoiMDAwZTU3ODAtNDkxYS0xMWVjLWJhYTctMWIxZjE0NjZmM2ViIiwibHRpIjpmYWxzZSwiaWF0IjoxNjk5ODg3ODY3LCJleHAiOjE3MDA0OTI2Njd9.kVXTn-bIwgnPZQf9Zzt4tQ2a6zn8vDoB01XI_-KA5aU'
        const appCourse = '53626420-c18d-11ed-82c2-eba03235e849'
        const appHost =
          'https://editor-backoffice.tangerine-qa.oneclicklabs.es/api'
        const lessonGuid = '6249ba40-c18a-11ed-82c2-eba03235e849'
        const unitGuid = 'unitGuid QA evento analisis'
        const appMapsEndPoint = appHost + '/app/blueberry/courses/' + appCourse + '/maps'
        // */

        // El token hay que hacer "login especial" con postman (Login BB App)
        /*
        const appToken =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJndWlkIjoiYjA4NjQ0YjAtYjg0Zi0xMWVkLWI1YTYtNWY4Y2MyYzQ3NTc3IiwidXNlcm5hbWUiOiJzdHVkZW50LTAyLTAxLWVzIiwiZW1haWwiOiJzdHVkZW50LTAyLTAxLWVzIiwicm9sZV9ndWlkIjoiUjAxIiwibmFtZSI6IkFsdW1ubyIsImxhc3RuYW1lIjoiT25lQ2xpY2sgMSIsImF2YXRhciI6IiIsImxhbmdfaWQiOm51bGwsInRlbmFudF91cmwiOiJodHRwczovL2JsdWViZXJyeS50YW5nZXJpbmUtZGV2MS5vbmVjbGlja2xhYnMuZXMvYXBpLyIsInRlbmFudF9ndWlkIjoiMjQ5MmFmMjktMGY0My00YWQ4LWI5ZjgtMGZjZWY2ZDI1MDhhIiwibHRpIjpmYWxzZSwiaWF0IjoxNjk4OTY5NDc1LCJleHAiOjE2OTk1NzQyNzV9.Z2FwTBwsmj7fj3HCIt_uTMdYtt4hc0xXj3GE7i7nc1U'
        const appHost =
          'https://editor-backoffice.tangerine-dev.oneclicklabs.es/api'
        const appCourse = '95119ff0-b853-11ed-b5a6-5f8cc2c47577'
        const appMapsEndPoint =
          appHost + '/app/blueberry/courses/' + appCourse + '/maps'
        const lessonGuid = 'c4c0f2e0-b84f-11ed-b5a6-5f8cc2c47577'
        const unitGuid = 'XYZ para evento analisis'
        // */
        //*
        const appToken =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJndWlkIjoiMzFkZTAyYzAtYWQyMi0xMWVkLWI3ZmQtOTk4ODFmNWRiNTM1IiwidXNlcm5hbWUiOiJzdHVkZW50LTA1LTA1LWVzIiwiZW1haWwiOiJzdHVkZW50LTA1LTA1LWVzIiwicm9sZV9ndWlkIjoiUjAxIiwibmFtZSI6IkFsdW1ubyIsImxhc3RuYW1lIjoiT25lQ2xpY2sgNSIsImF2YXRhciI6IiIsImxhbmdfaWQiOm51bGwsInRlbmFudF91cmwiOiJodHRwczovL2JsdWViZXJyeS50YW5nZXJpbmUtZGV2MS5vbmVjbGlja2xhYnMuZXMvYXBpLyIsInRlbmFudF9ndWlkIjoiMjQ5MmFmMjktMGY0My00YWQ4LWI5ZjgtMGZjZWY2ZDI1MDhhIiwibHRpIjpmYWxzZSwiaWF0IjoxNzA2NzgzMzQ2LCJleHAiOjE3MDczODgxNDZ9.MqkpgkULHDcf0STQ1W-nMmOHEml2mn662FgeJMj79xk'
        const appHost =
          'https://editor-backoffice.tangerine-dev.oneclicklabs.es/api'
        const appCourse = '40caa8b0-ad22-11ed-b7fd-99881f5db535'
        const appMapsEndPoint =
          appHost + '/app/blueberry/courses/' + appCourse + '/maps'
        // const lessonGuid = '326422b0-ad22-11ed-b7fd-99881f5db535'
        const unitGuid = '32611570-ad22-11ed-b7fd-99881f5db535'
        const lessonGuid = '326422b0-ad22-11ed-b7fd-99881f5db535'
        // */
        /* scaffold
        const appToken =
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJndWlkIjoiMzFkZTAyYzAtYWQyMi0xMWVkLWI3ZmQtOTk4ODFmNWRiNTM1IiwidXNlcm5hbWUiOiJzdHVkZW50LTA1LTA1LWVzIiwiZW1haWwiOiJzdHVkZW50LTA1LTA1LWVzIiwicm9sZV9ndWlkIjoiUjAxIiwibmFtZSI6IkFsdW1ubyIsImxhc3RuYW1lIjoiT25lQ2xpY2sgNSIsImF2YXRhciI6IiIsImxhbmdfaWQiOm51bGwsInRlbmFudF91cmwiOiJodHRwczovL2JsdWViZXJyeS50YW5nZXJpbmUtZGV2MS5vbmVjbGlja2xhYnMuZXMvYXBpLyIsInRlbmFudF9ndWlkIjoiMjQ5MmFmMjktMGY0My00YWQ4LWI5ZjgtMGZjZWY2ZDI1MDhhIiwibHRpIjpmYWxzZSwiaWF0IjoxNzA2NzgzMzQ2LCJleHAiOjE3MDczODgxNDZ9.MqkpgkULHDcf0STQ1W-nMmOHEml2mn662FgeJMj79xk'
        const appHost =
          'https://editor-backoffice.tangerine-dev.oneclicklabs.es/api'
        const appCourse = '2d264aa0-be87-11ed-b2ed-a9c20a2e53e3'
        const appMapsEndPoint =
          appHost + '/app/blueberry/courses/' + appCourse + '/maps'
        const lessonGuid = '561f0f30-be84-11ed-b2ed-a9c20a2e53e3'
        const unitGuid = '556e0eb0-be84-11ed-b2ed-a9c20a2e53e3'
        // */

        const appMaps = await fetch(appMapsEndPoint, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${appToken}`,
            'Content-Type': 'application/json'
          }
        }).then(async (response) => (await response.json()).data)

        const lessons = appMaps.units.reduce((accumulator, unit) => {
          unit.lessons.forEach((lesson) => {
            accumulator[lesson.lesson_guid] = lesson
          })
          return accumulator
        }, {})

        const appReactNativeObject = {
          function: 'startPractice',
          params: {
            apiHost: appHost,
            lesson: {
              lesson_guid: lessonGuid,
              challenges: lessons[lessonGuid].challenges,
              status: lessons[lessonGuid].status,
              pieces: { pieces: [1, 1, 0] },
              unit_guid: unitGuid
            },
            courseGuid: appCourse,
            userToken: appToken,
            language: 'ES',
            requiresAudioAssistance: false,
            user: {
              email: 'test-app@fakemail.com',
              guid: 'test-app-player-guid'
            }
          }
        }
        // ocLog('start pr ->\n lesson', appReactNativeObject.params.lesson)
        window.postMessage(JSON.stringify(appReactNativeObject))
      }, 200)
    }
    // Para Backoffice
    if (false) {
      setTimeout(async () => {
        ocLog('Backoffice local test')
        const lemonadePreviewData = {
          function: 'loadActivityPreview',
          params: {
            /*
            // scaffolding
            activity: {
              id: 'c7fb04a4-9278-434f-833f-ef1d90ae4ace',
              type: 'graph',
              questions: [
                {
                  title: '',
                  type: 'clozeformula',
                  metadata: {
                    name: 'Cloze math'
                  },
                  data: {
                    type: 'clozeformula',
                    stimulus:
                      '<p>Una de las mayores atracciones turísticas en TurqUía son los viajes en globo aerostático, que suelen volar a una altura de <span class="no-break">202 m.</span> Aproxima esta altura a las centenas.</p>',
                    template: '<p>La centena más próxima es {{response}}.</p>',
                    validation: {
                      scoring_type: 'exactMatch',
                      immediateFeedback: false,
                      alt_responses: [],
                      valid_response: {
                        score: 1,
                        value: [
                          {
                            method: 'equivLiteral',
                            options: {
                              decimalPlaces: 0
                            },
                            feedback: '',
                            value: '200'
                          }
                        ]
                      }
                    },
                    feedback: {
                      successMessage: '',
                      errorMessages: [],
                      responseMessages: [],
                      withFeedbackPanel: true,
                      config: {
                        visibility: true,
                        sequence: 'stack'
                      }
                    },
                    isEvaluable: true,
                    attempts: {
                      attempts: 0
                    },
                    ui_style: {
                      type: '',
                      customClass: '',
                      keyboard: {
                        type: 'NUMERICAL',
                        isFloating: true
                      }
                    },
                    responses: [
                      {
                        feedback: ''
                      }
                    ],
                    hints: [],
                    feedback_responses: [
                      {
                        feedback: ''
                      }
                    ]
                  },
                  reference: 'ebc9f07f-0fc2-4ed0-94f9-50b1bf4e22c7'
                },
                {
                  title: '',
                  type: 'clozeformula',
                  metadata: {
                    name: 'Cloze math'
                  },
                  data: {
                    type: 'clozeformula',
                    stimulus:
                      '<p>Sin aproximar, ¿a qué altura suelen volar los globos aerostéticos?</p>',
                    template: '<p>Vuelan a {{response}} m de altura.</p>',
                    validation: {
                      scoring_type: 'exactMatch',
                      immediateFeedback: false,
                      alt_responses: [],
                      valid_response: {
                        score: 1,
                        value: [
                          {
                            method: 'equivLiteral',
                            options: {
                              decimalPlaces: 2
                            },
                            feedback: '',
                            value: '202'
                          }
                        ]
                      }
                    },
                    feedback: {
                      successMessage: '',
                      errorMessages: [],
                      responseMessages: [],
                      withFeedbackPanel: true,
                      config: {
                        visibility: true,
                        sequence: 'stack'
                      }
                    },
                    isEvaluable: true,
                    attempts: {
                      attempts: 0
                    },
                    ui_style: {
                      type: '',
                      customClass: '',
                      keyboard: {
                        type: 'NUMERICAL',
                        isFloating: true
                      }
                    },
                    responses: [
                      {
                        feedback: ''
                      }
                    ],
                    hints: [],
                    feedback_responses: [
                      {
                        feedback: ''
                      }
                    ]
                  },
                  reference: 'ec143163-ad3c-4968-8c79-2ecb0a07d8d9'
                },
                {
                  title: '',
                  type: 'mcq',
                  data: {
                    type: 'mcq',
                    options: [
                      {
                        label:
                          '<p>Aproximar la altura a la que vuelan los globos aerostáticos a las decenas.</p>',
                        value: 0,
                        feedback: ''
                      },
                      {
                        label:
                          '<p>Aproximar la altura a la que vuelan los globos aerostáticos a las centenas.</p>',
                        value: 1,
                        feedback: ''
                      },
                      {
                        label:
                          '<p>Aproximar la altura a la que vuelan los globos aerostáticos a las unidades de millar.</p>',
                        value: 2,
                        feedback: ''
                      }
                    ],
                    stimulus: '<p>¿Qué pide el enunciado?</p>',
                    shuffle_options: true,
                    validation: {
                      scoring_type: 'exactMatch',
                      immediateFeedback: false,
                      alt_responses: [],
                      valid_response: {
                        score: 1,
                        value: ['1']
                      }
                    },
                    ui_style: {
                      type: 'Multiple choice – standard',
                      columns: 1,
                      labelType: '',
                      customClass: '',
                      inlineFeedback: false
                    },
                    isEvaluable: true,
                    attempts: {
                      attempts: 0
                    },
                    feedback: {
                      successMessage: '',
                      errorMessages: [],
                      responseMessages: [],
                      withFeedbackPanel: true,
                      config: {
                        visibility: true,
                        sequence: 'stack'
                      }
                    },
                    showCheckIcon: true,
                    hints: []
                  },
                  metadata: {
                    name: 'Multiple choice – standard'
                  },
                  reference: 'af9211e4-5a97-48a3-8480-5e8f6eac5d33'
                },
                {
                  title: '',
                  type: 'clozedropdown',
                  metadata: {
                    name: 'Cloze with drop down'
                  },
                  data: {
                    possible_responses: [
                      ['decenas', 'centenas', 'unidades de millar'],
                      ['la más lejana', 'la más cercana']
                    ],
                    response_container: {
                      pointer: 'left'
                    },
                    stimulus: '<p>Completa el siguiente texto.</p>',
                    template:
                      'Para aproximar un número a las centenas, hay que buscar entre qué dos {{response}} se encuentra y elegir {{response}}.',
                    type: 'clozedropdown',
                    case_sensitive: true,
                    validation: {
                      scoring_type: 'exactMatch',
                      immediateFeedback: false,
                      alt_responses: [],
                      valid_response: {
                        score: 1,
                        value: ['centenas', 'la más cercana']
                      }
                    },
                    title: '',
                    isEvaluable: true,
                    attempts: {
                      attempts: 0
                    },
                    feedback: {
                      successMessage: '',
                      errorMessages: [],
                      responseMessages: [],
                      withFeedbackPanel: true,
                      config: {
                        visibility: true,
                        sequence: 'stack'
                      }
                    },
                    ui_style: {
                      type: '',
                      customClass: ''
                    },
                    hints: [],
                    feedback_responses: [
                      {
                        feedback: ''
                      },
                      {
                        feedback: ''
                      }
                    ]
                  },
                  reference: '5e9f43da-602e-4115-8a44-987d5ce10279'
                },
                {
                  title: '',
                  type: 'clozeformula',
                  metadata: {
                    name: 'Cloze math'
                  },
                  data: {
                    type: 'clozeformula',
                    stimulus:
                      '<p>202 está entre 200 y 300. ¿Cuántas unidades lo separan de cada centena?</p>',
                    template:
                      '<p>202 está a {{response}} unidades de 200.</p><p>202 está a {{response}} unidades de 300.</p>',
                    validation: {
                      scoring_type: 'exactMatch',
                      immediateFeedback: false,
                      alt_responses: [],
                      valid_response: {
                        score: 1,
                        value: [
                          {
                            method: 'equivLiteral',
                            options: {
                              decimalPlaces: 2
                            },
                            feedback: '',
                            value: '2'
                          },
                          {
                            method: 'equivLiteral',
                            options: {
                              decimalPlaces: 2
                            },
                            feedback: '',
                            value: '98'
                          }
                        ]
                      }
                    },
                    feedback: {
                      successMessage: '',
                      errorMessages: [],
                      responseMessages: [],
                      withFeedbackPanel: true,
                      config: {
                        visibility: true,
                        sequence: 'stack'
                      }
                    },
                    isEvaluable: true,
                    attempts: {
                      attempts: 0
                    },
                    ui_style: {
                      type: '',
                      customClass: '',
                      keyboard: {
                        type: 'NUMERICAL',
                        isFloating: true
                      }
                    },
                    responses: [
                      {
                        feedback: ''
                      }
                    ],
                    hints: [],
                    feedback_responses: [
                      {
                        feedback: ''
                      },
                      {
                        feedback: ''
                      }
                    ]
                  },
                  reference: 'f1fd62ce-e8c6-490f-bc9e-e08aeab9ea28'
                },
                {
                  title: '',
                  type: 'clozeformula',
                  metadata: {
                    name: 'Cloze math'
                  },
                  data: {
                    type: 'clozeformula',
                    stimulus:
                      '<p>Sabiendo que 202 está a 2 unidades de 200 y a 98 unidades de 300, completa el siguiente texto.</p>',
                    template:
                      '<p>La centena más próxima a los 202 m de altura a los que vuela un globo aerostático es {{response}}.</p>',
                    validation: {
                      scoring_type: 'exactMatch',
                      immediateFeedback: false,
                      alt_responses: [],
                      valid_response: {
                        score: 1,
                        value: [
                          {
                            method: 'equivLiteral',
                            options: {
                              decimalPlaces: 2
                            },
                            feedback: '',
                            value: '200'
                          }
                        ]
                      }
                    },
                    feedback: {
                      successMessage: '',
                      errorMessages: [],
                      responseMessages: [],
                      withFeedbackPanel: true,
                      config: {
                        visibility: true,
                        sequence: 'stack'
                      }
                    },
                    isEvaluable: true,
                    attempts: {
                      attempts: 0
                    },
                    ui_style: {
                      type: '',
                      customClass: '',
                      keyboard: {
                        type: 'NUMERICAL',
                        isFloating: true
                      }
                    },
                    responses: [
                      {
                        feedback: ''
                      }
                    ],
                    hints: [],
                    feedback_responses: [
                      {
                        feedback: ''
                      }
                    ]
                  },
                  reference: 'f25e5753-1fe7-400e-ba59-6de60a389ac4'
                }
              ]
            }
            */
            //*
            // gráfica
            activity: {
              title: '',
              widget_type: 'response',
              metadata: {
                name: 'Match list'
              },
              type: 'association',
              data: {
                possible_responses: ['29 °C.', '12 °C.', '8 °C.'],
                stimulus:
                  '<p>En esta curva de frecuencias se muestran las temperaturas mínimas y máximas recogidas en un pueblo. Arrastra cada temperatura a la oración correspondiente para completarla.</p><div class="fr-chart ct-chart ct-minor-seventh" data-chart=\'{"type": "line", "series": [{"name": "°C mínimas", "data": [18,10,12,13,8]},{"name": "°C máximas", "data": [29,21,25,26,23]}], "labels":["1","2","3","4","5"], "options":{"low":0, "axisY": {"onlyInteger": true}}}\'></div>',
                stimulus_list: [
                  'La temperatura máxima del día 1 fue de…',
                  'La temperatura mínima del día 3 fue de…',
                  'La temperatura mínima del día 5 fue de…'
                ],
                type: 'association',
                validation: {
                  scoring_type: 'exactMatch',
                  immediateFeedback: false,
                  alt_responses: [],
                  valid_response: {
                    score: 1,
                    value: ['29 °C.', '12 °C.', '8 °C.']
                  }
                },
                shuffle_options: true,
                duplicate_responses: false,
                isEvaluable: true,
                attempts: {
                  attempts: 0
                },
                feedback: {
                  successMessage: '',
                  errorMessages: [
                    '<p>La altura que alcanza cada línea representa la temperatura mínima y máxima de cada día, respectivamente.</p>'
                  ],
                  responseMessages: [],
                  withFeedbackPanel: true,
                  config: {
                    visibility: true,
                    sequence: 'stack'
                  }
                },
                ui_style: {
                  type: '',
                  customClass: ''
                },
                hints: [
                  '<p>La altura que alcanza cada línea representa la temperatura mínima y máxima de cada día, respectivamente.</p>'
                ],
                feedback_responses: [
                  {
                    feedback: ''
                  },
                  {
                    feedback: ''
                  },
                  {
                    feedback: ''
                  }
                ]
              },
              reference: '98fe011a-5e74-4365-b223-8bb2b481a87c'
            }
            // */
          }
        }

        window.postMessage(JSON.stringify(lemonadePreviewData))
      }, 200)
    }
    /* eslint-enable */
    // Pruebas local -------------------------------------------- (final)

    return () => {
      window.removeEventListener('message', messageFromReactNative)
      document.removeEventListener('message', messageFromReactNative)
    }
  }, [])

  const messageFromReactNative = (event) => {
    try {
      const dataFromRN = JSON.parse(event.data)

      if (
        dataFromRN?.function === 'startPractice' ||
        dataFromRN?.function === 'startPracticeWeb'
      ) {
        const paramsRN = dataFromRN.params

        setAppHostReactNative(paramsRN.apiHost) // Establecer host
        setAppTokenReactNative(paramsRN.userToken) // Establecer userToken

        if (paramsRN.userDevMode !== undefined) {
          store.dispatch(
            updateDevMode({ isDevMode: paramsRN.userDevMode === 1 })
          )
        }

        // Establecer datos base de la práctica
        const usableChallenge =
          paramsRN.lesson.status === 'broked_start' &&
          paramsRN.lesson.challenges[1]
            ? paramsRN.lesson.challenges[1]
            : paramsRN.lesson.challenges[0]

        setPracticeBaseData({
          courseGuid: paramsRN.courseGuid,
          lessonGuid: paramsRN.lesson.lesson_guid,
          unitGuid: paramsRN.lesson.unit_guid,
          lessonChallenge: usableChallenge.name,
          lessonChallengeExp: usableChallenge.exp,
          lessonStatus: paramsRN.lesson.status,
          lessonPiecesHave: paramsRN.lesson.pieces.have,
          lessonPiecesTotal: paramsRN.lesson.pieces.pieces,
          earnedCoins: paramsRN.lesson.challenge_extra_effort_coins,
          language: paramsRN.language,
          requiresAudioAssistance: paramsRN.requiresAudioAssistance,
          statusBarHeight: paramsRN?.statusBarHeight || null,
          userEmail: paramsRN?.user?.email,
          username: paramsRN?.user?.username,
          userGuid: paramsRN?.user?.guid,
          isFromApp: dataFromRN?.function === 'startPractice'
        })

        // ocLog('ini challenge', usableChallenge.name)
        // ocLog('ini status', paramsRN.lesson.status)

        // Se usa el idioma que informa ReactNative
        // Sólo desde App, en Web lo coge del navegador y del programa
        if (paramsRN.language && dataFromRN.function !== 'startPracticeWeb') {
          setCustomLanguageCode(paramsRN.language.toLowerCase())
          initLang()
        }

        // Se usa el 'educational_internal_level' que informa ReactNative
        // Sólo desde App, en Web se almacena al recoger los datos del curso
        if (
          paramsRN.educational_internal_level &&
          dataFromRN.function !== 'startPracticeWeb'
        ) {
          store.dispatch(
            setCourse({
              educational_internal_level: paramsRN.educational_internal_level
            })
          )
        }

        setPracticeState(1)
      } else if (dataFromRN?.function === 'loadActivityPreview') {
        setPracticeBaseData(dataFromRN.params)
        setPracticeState(1)
      }
    } catch (e) {}
  }

  const messageToReactNative = (message) => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message))
    } else {
      window.postMessage(JSON.stringify(message))
      window?.parent && window.parent.postMessage(JSON.stringify(message), '*')
    }
  }

  return (
    <div className="app-practice-page">
      {PRACTICE_STATES[practiceState] === 'LOADING' && (
        <ActivityLoading
          title={translate('app_practice_loading_title')}
          message={translate('app_practice_loading_message')}
        />
      )}

      {PRACTICE_STATES[practiceState] === 'PRACTICING' && (
        <PracticeView
          practiceBaseData={practiceBaseData}
          setPracticeBaseData={setPracticeBaseData}
          practiceProgressData={practiceProgressData}
          setPracticeProgressData={setPracticeProgressData}
          practiceState={practiceState}
          setPracticeState={setPracticeState}
          messageToReactNative={messageToReactNative}
          isIphone={isIphone}
          pointsReward={pointsReward}
          setResetActivityGamification={setResetActivityGamification}
        />
      )}

      {PRACTICE_STATES[practiceState] === 'COMPLETED' && (
        <PracticeCompleteView
          practiceBaseData={practiceBaseData}
          setPracticeBaseData={setPracticeBaseData}
          practiceProgressData={practiceProgressData}
          setPracticeProgressData={setPracticeProgressData}
          setPracticeState={setPracticeState}
          messageToReactNative={messageToReactNative}
          isIphone={isIphone}
          pointsReward={pointsReward}
        />
      )}
    </div>
  )
}

export default AppPracticePage
