import React, { useEffect, useState } from 'react'
import { Analysis } from '../../services/analysisService'
import { clearToken, getToken } from '../../services/util'
import { clearQAMode } from '../../services/qaModeService'
import { sendIssuesAutomaticReport } from '../AppPracticePage/services/practiceServicesReactNative'
import { getWhoAmI } from '../../services/courseInfoService'
import { isAndroidDevice, isIosDevice } from '../../util/devices'
import GenericError from './GenericError'

const ErrorUserContentPage = () => {
  const [isReportSended, setIsReportSended] = useState(false)

  useEffect(() => {
    const errorPath = window.location.pathname.substring(1)
    const errorString = errorPath.replace('error-', '')

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Error,
      'Error Page ' + errorString
    )

    if (errorString !== 'BBE-108' && getToken()) {
      sendErrorNotification(errorString)
    } else {
      setIsReportSended(true)
    }
  }, [])

  const sendErrorNotification = async (error) => {
    const playerWhoAmI = await getWhoAmI()
    const playerGuid = playerWhoAmI.guid
    let userEmail = playerWhoAmI.email
    const username = playerWhoAmI.username
    // Si es hijo y no tiene email, se pide al padre
    if (!playerWhoAmI.userEmail) {
      const mainWhoAmI = await getWhoAmI(true)
      userEmail = mainWhoAmI.email
    }

    let errorComment = ''
    if (isIosDevice()) errorComment = 'On iOS.'
    else if (isAndroidDevice()) errorComment = 'On Android.'
    else errorComment = 'On desktop.'

    switch (error) {
      case 'BBE-100':
        errorComment += ' No course.'
        break
      case 'BBE-101':
        errorComment += ' There are no unit items.'
        break
      case 'BBE-102':
        errorComment += ' There are no lesson items.'
        break
      case 'BBE-103':
        errorComment += ' There is no object on the map for unit items.'
        break
      case 'BBE-104':
        errorComment += ' There is no object on the map for lesson items.'
        break
      case 'BBE-105':
        errorComment +=
          ' There are not enough objects on the map for the unit items.'
        break
      case 'BBE-106':
        errorComment +=
          ' There are not enough objects on the map for the lessons items.'
        break
      case 'BBE-107':
        errorComment += ' Unknown portal.'
        break
      case 'BBE-108':
        errorComment += ' No license.'
    }

    await sendIssuesAutomaticReport({
      username,
      userEmail,
      playerGuid,
      issues: [error],
      comment: errorComment,
      userAgent: window.navigator.userAgent
    })

    clearQAMode()
    clearToken()
    setIsReportSended(true)
  }

  return <GenericError disableButton={!isReportSended} />
}

export default ErrorUserContentPage
