import { configureStore } from '@reduxjs/toolkit'
import UnitsReducer, {
  changeLessonsStatus,
  initUnits,
  resetUnits
} from './UnitsSlice/UnitsSlice'
import PracticeReducer, {
  resetPractice,
  setProgress
} from './PracticeSlice/PracticeSlice'
import DailyGoalReducer, {
  resetDailyGoal
} from './DailyGoalSlice/DailyGoalSlice'
import MetaberryReducer, {
  resetMetaberry
} from './MetaberrySlice/MetaberrySlice'
import SettingsReducer, { resetSettings } from './SettingsSlice/SettingsSlice'
import LearningMapReducer, {
  addFirstTimeClouds,
  initLearningMap,
  resetLearningMap
} from './LearningMap/LearningMapSlice'
import ConfigurationReducer, {
  initializeStore,
  resetStoreConfiguration
} from './Configuration/Configuration'
import LoadingReducer, { hideLoading } from './Loading/Loading'
import { fetchLearningMap } from '../services/learningMapService'
import UserExperience, {
  resetUserExperience
} from './UserExperienceSlice/UserExperienceSlice'
import Rewards, { resetRewards } from './RewardsSlice/RewardsSlice'
import { updateCourseInfo } from '../services/courseInfoService'
import SubscriptionReducer from './SubscriptionSlice/SubscriptionSlice'
import popupManagerReducer from './PopupManagerSlice/PopupManagerSlice'
import rankingReducer, {
  resetRankings,
  setUnitsTopRankedAndUserRanking
} from './RankingSlice/RankingSlice'
import CourseReducer, {
  resetCourse,
  setCourseUnitsImages
} from './Course/Course'
import {
  getUnitsImages,
  getUnitsTopRankedAndUserRanking
} from '../services/unitService'
import { getCourseGuid } from '../assets/data/api'

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['metaberry.unitPopupRef', 'payload.unitPopupRef'],
        // Ignore these paths in the state
        // ignoredPaths: ['metaberry.unitPopupRef']
      }
    }),

  reducer: {
    configuration: ConfigurationReducer,
    learningMap: LearningMapReducer,
    units: UnitsReducer,
    practice: PracticeReducer,
    loading: LoadingReducer,
    dailyGoal: DailyGoalReducer,
    metaberry: MetaberryReducer,
    subscription: SubscriptionReducer,
    settings: SettingsReducer,
    userExperience: UserExperience,
    rewards: Rewards,
    ranking: rankingReducer,
    popupManager: popupManagerReducer,
    course: CourseReducer
  }
})

export async function initStore() {
  const learningMapData = await fetchLearningMap()
  await updateCourseInfo()
  store.dispatch(initializeStore())
  store.dispatch(initUnits({ learningMapData }))
  store.dispatch(initLearningMap({ learningMapData }))

  const courseUnitsImages = await getUnitsImages()
  store.dispatch(setCourseUnitsImages(courseUnitsImages))

  const courseGuid = await getCourseGuid()
  const unitsGuid = store.getState()?.units?.map((unit) => unit.unit_guid)
  const unitsTopRankedAndUserRanking = await getUnitsTopRankedAndUserRanking(
    courseGuid,
    unitsGuid
  )
  store.dispatch(setUnitsTopRankedAndUserRanking(unitsTopRankedAndUserRanking))
}

export async function resetStore() {
  store.dispatch(resetSettings())
  store.dispatch(resetDailyGoal())
  store.dispatch(resetUserExperience())
  store.dispatch(resetPractice())
  store.dispatch(resetUnits())
  store.dispatch(resetMetaberry())
  store.dispatch(resetLearningMap())
  store.dispatch(hideLoading())
  store.dispatch(resetStoreConfiguration())
  store.dispatch(resetRewards())
  store.dispatch(resetRankings())
  store.dispatch(resetCourse())
}

window.addClouds = (idx) => store.dispatch(addFirstTimeClouds({ idx }))
window.addProgress = (val) => store.dispatch(setProgress(val))
window.setLessonsStatus = (status) =>
  store.dispatch(changeLessonsStatus(status))
