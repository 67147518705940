/* eslint-disable */
import { getCourseGuid } from '../assets/data/api'
import { store } from '../store/configureStore'
import { createEndPoint, fetchWithToken } from './util'
// import lessonButtonStatusMock from './mocks/unit_lessonButtonStatus.json'
// import lessonTooltipButtonsMock from './mocks/unit_lessonTooltipButtonsMock.json'

// const mockFile = lessonTooltipButtonsMock
const mockFile = undefined

export async function FetchUnit(guid) {
  if (mockFile) return Promise.resolve(mockFile.data)
  const courseGuid = await getCourseGuid()
  const endPoint = `lms/courses/${courseGuid}/blueberry/units/${guid}/lessons`
  return fetchWithToken(createEndPoint(endPoint)).then(
    (json) => json.data.sort((a, b) => a.order - b.order)
    // si se quita el challenge no se muestra
    // return removeChallenge (json.data)
  )
}

/*
function removeChallenge(data) {
  return data.map((lesson) => {
    return produce(lesson, (draft) => {
      draft.challenges = lesson.challenges.filter(
        (challenge) => challenge.name !== 'upgrade'
      )
    })
  })
}
*/

export async function getUnitsImages() {
  const courseGuid = await getCourseGuid()
  const endPoint = `lms/courses/${courseGuid}/blueberry/phaser/unit-order`

  return fetchWithToken(createEndPoint(endPoint))
    .then((response) => {
      if (response.status === 'success' && response.data) {
        return response.data
      } else return []
    })
    .catch((error) => {
      console.error('Error getting units images:', error)
      return []
    })
}

export async function getUnitsTopRankedAndUserRanking(courseGuid, unitsGuid) {
  const endPoint = `game-engine/points/top-users-and-rank-per-unit?courseGuid=${courseGuid}&unitGuids[]=${unitsGuid}`
  return fetchWithToken(createEndPoint(endPoint))
    .then((response) => {
      if (response.status === 'success' && response.data) {
        return response.data
      } else return []
    })
    .catch((error) => {
      console.error('Error getting units rankers:', error)
      return []
    })
}
