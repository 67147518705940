import { getConfig } from '@oneclick/react-lemonade-editor'
import { useEffect, useRef, useState } from 'react'
import { ACTIVITY_TYPES } from '../../constants/ActivityConstants'

export function useActivityView({
  activityRef,
  activityType,
  currentActivity,
  activityContentRef,
  isFeedbackVisible,
  activityStore,
  exerciseStore,
  isScaffold,
  scaffoldCurrentQuestion,
  showAllFeedback,
  onActivityEvent,
  setActivityStore,
  initializeActivity,
  setIsFeedbackVisible,
  updateExercise,
  isResponseSend,
  exerciseLanguage
}) {
  const MINIMUN_PATHWAY_CELL_SIZE = 40

  const resizeTimer = useRef(null)
  const scrollTimer = useRef(null)
  const [activityWidth, setActivityWidth] = useState(window.innerWidth)
  const [isTopScrollShadowActive, setIsTopScrollShadowActive] = useState(false)
  const [isBottomScrollShadowActive, setIsBottomScrollShadowActive] =
    useState(false)
  const [isScrollMarkActive, setIsScrollMarkActive] = useState(false)
  const [footerHeight, setFooterHeight] = useState(0)
  const [showActivityFooter, setShowActivityFooter] = useState(true)
  const [pathwayCellSize, setPathwayCellSize] = useState(null)

  const [isScaffoldMobileVersion, setIsScaffoldMobileVersion] = useState(false)

  useEffect(() => {
    getConfig().setShowFeedback(showAllFeedback)
    // Temporal hasta que lo cambien en Lemonade
    getConfig().overwriteTranslationsByLang('es', {
      'scaffolding_navigation_toggle-show-activity': 'Ver actividad'
    })
  }, [showAllFeedback])

  useEffect(() => {
    const questionBodyObservers = []

    // Observer para scroll de sacaffolding steps
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach((node) => {
            // Para scroll de sacaffolding steps
            if (
              node?.classList &&
              node.classList.contains('scaffold-separator')
            ) {
              setTimeout(() => {
                // node.scrollIntoView({ behavior: 'smooth', block: 'start' })
                node.scrollIntoView({
                  block: 'center',
                  inline: 'nearest',
                  behavior: 'smooth'
                })
                checkScrollShadows()
              }, 150)
            }

            // Para ajustar el ancho de los elementos que generan scroll horizontal en .lemo-question-body
            if (
              node?.classList &&
              node.classList.contains('lemo-question') &&
              node?.querySelectorAll &&
              node.querySelectorAll('.lemo-question-body')
            ) {
              // Timeout para esperar a que el ejercicio se renderize
              setTimeout(() => {
                checkWidthToFitWideActivity(node)

                const resizeObserver = new ResizeObserver(() => {
                  checkWidthToFitWideActivity(node)
                })

                resizeObserver.observe(node)
                questionBodyObservers.push(resizeObserver)
              }, 150)
            }

            // ------
          })
        }
      })
    })

    // Start observing
    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    const _activityWidth = activityRef?.current?.offsetWidth
    setActivityWidth(_activityWidth)

    const handleResize = () => {
      if (resizeTimer.current) clearTimeout(resizeTimer.current)
      resizeTimer.current = setTimeout(() => {
        getFooterHeight()

        checkScrollShadows()

        const currentWidth = activityRef?.current?.offsetWidth
        if (currentWidth) {
          setActivityWidth(currentWidth)
        }

        // Reajusar ancho de ejercicios
        const nodes = document.querySelectorAll('.lemo-question')
        nodes.forEach((node) => {
          if (
            node?.querySelectorAll &&
            node.querySelectorAll('.lemo-question-body')
          ) {
            checkWidthToFitWideActivity(node)
          }
        })
      }, 100)
    }

    window.addEventListener('resize', handleResize)

    getFooterHeight()

    const boundCheckScrollShadows = () => {
      setIsScrollMarkActive(false)
      checkScrollShadows()
    }
    activityContentRef.current.addEventListener(
      'scroll',
      boundCheckScrollShadows
    )
    const boundRemoveScrollMark = () => {
      setTimeout(() => {
        setIsScrollMarkActive(false)
      }, 0)
    }
    // activityContentRef.current.addEventListener('scroll', boundRemoveScrollMark)
    // if (activityType !== ACTIVITY_TYPES.QUIZ) {
    // window.addEventListener('click', boundRemoveScrollMark)
    // window.addEventListener('touchstart', boundRemoveScrollMark)
    // window.addEventListener('wheel', boundRemoveScrollMark)
    window.addEventListener('scroll', boundRemoveScrollMark)

    checkScrollStimulus()
    checkScrollShadows()
    checkScrollMark()
    // }

    return () => {
      // activityContentRef?.current?.removeEventListener('scroll', boundRemoveScrollMark)
      // if (activityType !== ACTIVITY_TYPES.QUIZ) {
      // window.removeEventListener('click', boundRemoveScrollMark)
      // window.removeEventListener('touchstart', boundRemoveScrollMark)
      // window.removeEventListener('wheel', boundRemoveScrollMark)
      window.removeEventListener('scroll', boundRemoveScrollMark)
      // }

      activityContentRef?.current?.removeEventListener(
        'scroll',
        boundCheckScrollShadows
      )
      window.removeEventListener('resize', handleResize)
      observer.disconnect()
      questionBodyObservers.forEach((questionBodyObserver) =>
        questionBodyObserver.disconnect()
      )
    }
  }, [])

  useEffect(() => {
    setActivityStore(exerciseStore)
    window.lemonadeApi = exerciseStore
  }, [exerciseStore])

  useEffect(() => {
    const _isScaffold = currentActivity.type === 'scaffold'

    if (
      _isScaffold &&
      (activityType === ACTIVITY_TYPES.PRACTICE ||
        activityType === ACTIVITY_TYPES.PREVIEW)
    ) {
      setIsScaffoldMobileVersion(true)
    } else {
      setIsScaffoldMobileVersion(false)
    }

    if (activityType !== ACTIVITY_TYPES.QUIZ) {
      initializeActivity(_isScaffold)
    }

    if (currentActivity && !_isScaffold) {
      updateExercise({
        data: currentActivity,
        lang: exerciseLanguage ? exerciseLanguage.toLowerCase() : 'en'
      })
    }

    if (activityType === ACTIVITY_TYPES.QUIZ) {
      onActivityEvent({
        name: 'Analisys Practice Activity Viewed Quiz',
        properties: {}
      })
    } else {
      onActivityEvent({
        name: 'Activity Viewed',
        properties: {
          is_scaffolding: _isScaffold
        }
      })
    }

    checkScrollStimulus()
    checkScrollShadows()
    checkScrollMark()
    getFooterHeight()

    // Si es pathway se indica, para forzar a obtener tamaño de celda y actualizar en siguiente ciclo
    checkPathwayExercise()
  }, [currentActivity])

  useEffect(() => {
    if (isFeedbackVisible || isResponseSend) {
      scrollActivityContent()
    }
    checkScrollShadows()
    setIsScrollMarkActive(false)
  }, [activityStore, isFeedbackVisible, isResponseSend])

  useEffect(() => {
    if (isFeedbackVisible && (!isScaffold || scaffoldCurrentQuestion?.isMain)) {
      onActivityEvent({
        name: 'Activity Feedback Viewed',
        properties: {
          is_scaffolding: isScaffold
        }
      })
    }
  }, [isFeedbackVisible])

  useEffect(() => {
    // Es encesario un setTimeout porque si no no tiene el valor cargado
    setTimeout(() => {
      const individualFeedbackLists = document.querySelectorAll(
        '.lemo-feedback-list'
      )
      const individualFeedbackBlocks = [
        ...individualFeedbackLists,
        ...document.querySelectorAll('.lemo-inline-feedback')
      ]

      const individualFeedbackPins = [
        ...document.querySelectorAll('.lemo-feedback-pin-wrapper'),
        ...document.querySelectorAll('.lemo-feedback-pin-number.lemo-error')
      ]

      const showIndividualFeedkback =
        activityStore?.isEvaluated && activityStore?.showSolution === false

      individualFeedbackBlocks.forEach((element) => {
        const elementText = element?.textContent || ''
        const hasText = elementText.trim() !== ''

        element.style.display =
          showIndividualFeedkback && hasText ? 'block' : 'none'
      })

      individualFeedbackPins.forEach((element) => {
        const elementText = element?.textContent || ''
        const hasText = elementText.trim() !== ''

        element.style.display =
          showIndividualFeedkback &&
          hasText &&
          individualFeedbackLists.length > 0 &&
          individualFeedbackLists[0].hasChildNodes()
            ? 'flex'
            : 'none'
      })

      if (activityStore?.showSolution) {
        scrollActivityContent()
      }
    }, 10)
  }, [activityStore?.showSolution, activityStore?.isEvaluated])

  const checkScrollPosition = () => {
    const auxReference = activityContentRef.current

    if (auxReference) {
      const isScrolledToBottom =
        Math.abs(
          auxReference.scrollHeight -
            auxReference.scrollTop -
            auxReference.clientHeight
        ) < 2

      const isScrolledToTop = auxReference.scrollTop < 2

      return { isScrolledToTop, isScrolledToBottom }
    }

    return { isScrolledToTop: true, isScrolledToBottom: true }
  }

  // Para asegurar que lo primero que ve el estudiante es el enunciado
  const checkScrollStimulus = () => {
    setTimeout(() => {
      const contentElement = activityContentRef.current
      if (contentElement && contentElement.scrollTop > 0) {
        contentElement.scrollTop = 0
      }
    }, 0)
  }

  const checkScrollShadows = () => {
    if (scrollTimer.current) clearTimeout(scrollTimer.current)

    scrollTimer.current = setTimeout(() => {
      const scrollState = checkScrollPosition()
      setIsBottomScrollShadowActive(!scrollState.isScrolledToBottom)
      setIsTopScrollShadowActive(!scrollState.isScrolledToTop)
    }, 0)
  }

  const checkScrollMark = () => {
    setTimeout(() => {
      const scrollState = checkScrollPosition()
      setIsScrollMarkActive(!scrollState.isScrolledToBottom)
    }, 300)
  }

  const scrollContentAndHideScrollMark = () => {
    setIsScrollMarkActive(false)
    scrollActivityContent()
  }

  const toogleFeedback = () => {
    setIsFeedbackVisible(!isFeedbackVisible)
  }

  const scrollActivityContent = () => {
    setTimeout(() => {
      const _activityContent = document.querySelector('.activity-view_content')
      if (_activityContent !== null && _activityContent !== undefined) {
        const scrollBehavior = _activityContent.style.scrollBehavior
        _activityContent.style.scrollBehavior = 'smooth'
        _activityContent.scrollTop = _activityContent.scrollHeight
        _activityContent.style.scrollBehavior = scrollBehavior
      }
      // setIsScrollMarkActive(false)
    }, 200)
  }

  const checkPathwayExercise = () => {
    const isPathwayExercise =
      currentActivity?.type === 'pathway' ||
      currentActivity?.type === 'reversepathway'

    if (isPathwayExercise) {
      setTimeout(() => {
        // Se obtiene el tamaño de celda en base al espacio disponible y se actualiza el ejercicio para que se tenga en cuenta
        calculatePathwayCellSize()
        updateExercise({
          data: currentActivity,
          lang: exerciseLanguage ? exerciseLanguage.toLowerCase() : 'en'
        })
      }, 100)
    } else {
      setPathwayCellSize(null)
    }
  }

  const calculatePathwayCellSize = () => {
    let auxCellSize = MINIMUN_PATHWAY_CELL_SIZE
    const contentElement = activityContentRef.current

    // Comprobar posición de las instrucciones
    let noBoardModifierWidth = 0
    let noBoardModifierHeight = 0

    const noBoardElement =
      contentElement.querySelector('.lemo-pathway-instructions') ||
      contentElement.querySelector('.lemo-user-controls')

    const pathwayNoBoard = noBoardElement.getBoundingClientRect()
    const pathwayBoard = contentElement
      .querySelector('.lemo-pathway-board-wrapper')
      .getBoundingClientRect()

    if (
      pathwayNoBoard.right < pathwayBoard.left ||
      pathwayNoBoard.left > pathwayBoard.right
    ) {
      noBoardModifierWidth = pathwayNoBoard.width
    } else {
      noBoardModifierHeight = pathwayNoBoard.height
    }

    // Obtener altura de "area dummy"
    const dummyElement = contentElement.querySelector('.dummy-activity')
    const dummyHeight = dummyElement ? dummyElement.clientHeight : 0

    // Obtener altura de la cabecera de la pregunta
    const questionHeaderHeight = contentElement.querySelector(
      '.lemo-question-header'
    ).clientHeight

    // Obtener espacio disponible para el tablero
    const availableHeight =
      contentElement.clientHeight -
      dummyHeight -
      questionHeaderHeight -
      noBoardModifierHeight

    const availableWidth = contentElement.clientHeight - noBoardModifierWidth

    const cellHeightSize = availableHeight / currentActivity.data.board.rows
    const cellWidthSize = availableWidth / currentActivity.data.board.columns

    const calculatedCellSize = Math.floor(
      Math.min(cellHeightSize, cellWidthSize) * 0.88
    )

    if (calculatedCellSize > auxCellSize) {
      auxCellSize = calculatedCellSize
    }

    setPathwayCellSize(auxCellSize)
  }

  const getFooterHeight = () => {
    setTimeout(() => {
      let auxFooterHeight = 0
      if (activityRef?.current) {
        const footerElement =
          activityRef?.current.querySelector('.activity-footer')
        if (footerElement) {
          auxFooterHeight = footerElement.clientHeight
        }
      }

      setFooterHeight(auxFooterHeight)
    }, 250)
  }

  const onKeyboardVisible = (_data) => {
    // eslint-disable-next-line no-unneeded-ternary, no-extra-boolean-cast
    setShowActivityFooter(!!_data ? false : true)
  }

  // Busca el cuerpo de las preguntas y comprueba que tienen suficente espacio horizontal
  //  sino trata de darle lo necesario hasta 90vw para evitar el scroll
  const checkWidthToFitWideActivity = (node) => {
    const lemoExercise = document.querySelector('.lemonade-exercises')
    if (lemoExercise) {
      const originalExerciseWidht = document.querySelector(
        '.lemonade-exercises'
      ).offsetWidth

      node.querySelectorAll('.lemo-question-body').forEach((childNode) => {
        const questionHeader = childNode.parentElement.querySelector(
          '.lemo-question-header'
        )

        if (childNode.scrollWidth > childNode.offsetWidth) {
          childNode.style.position = 'relative'
          questionHeader.style.position = 'relative'
          childNode.style.width = childNode.scrollWidth + 'px'
          childNode.style.maxWidth = 'calc(100vw - 32px)'
          childNode.style.left = '50%'
          childNode.style.transform = 'translateX(-50%)'

          // Tambien se le da el mismo ancho al enunciado
          questionHeader.style.position = 'relative'
          questionHeader.style.width = childNode.scrollWidth + 'px'
          questionHeader.style.maxWidth = 'calc(100vw - 32px)'
          questionHeader.style.left = '50%'
          questionHeader.style.transform = 'translateX(-50%)'
        }

        // Y se ajusta el audioButton
        const audioButton = document.querySelector(
          '.activity-view__stimulus-audio-button .speak-button'
        )

        if (audioButton) {
          let widthDifference =
            Math.floor(questionHeader.offsetWidth - originalExerciseWidht) / 2
          if (widthDifference < 0) {
            widthDifference = 0
          }

          audioButton.style.position = 'relative'
          audioButton.style.left = widthDifference + 'px'
        }
      })
    }
  }

  return {
    showActivityFooter,
    activityWidth,
    footerHeight,
    isTopScrollShadowActive,
    isScrollMarkActive,
    isBottomScrollShadowActive,
    pathwayCellSize,
    isScaffoldMobileVersion,
    toogleFeedback,
    scrollContentAndHideScrollMark,
    onKeyboardVisible
  }
}
