import React, { useEffect, useState } from 'react'
import './BlueberryPopup.scss'
import { ReactComponent as CloseIconColor } from '../../../../../assets/icons/close_color.svg'
import { store } from '../../../../../store/configureStore'
import { setIsGameKeyboardBlocked } from '../../../../../store/MetaberrySlice/MetaberrySlice'

const BlueberryPopup = ({ title, onClose, children }) => {
  const [BackgroundCircleImage] = useState(
    `${process.env.PUBLIC_URL}/assets/graphics/images/background-gradient-circle.svg`
  )

  useEffect(() => {
    store.dispatch(setIsGameKeyboardBlocked(true))

    return () => {
      store.dispatch(setIsGameKeyboardBlocked(false))
    }
  }, [])

  return (
    <>
      <div
        className="meta-options-menu-drawer__backdrop"
        onClick={onClose}
      ></div>
      <div className="blueberry-popup">
        <div
          className="blueberry-popup_decoration"
          style={{ backgroundImage: `url(${BackgroundCircleImage})` }}
        ></div>
        <div className="blueberry-popup_close_image" onClick={onClose}>
          <CloseIconColor />
        </div>
        <div className="blueberry-popup_title">{title}</div>
        {children}
      </div>
    </>
  )
}

export default BlueberryPopup
