import { useEffect, useState } from 'react'
import { store } from '../../../store/configureStore'
import { setIsGameKeyboardBlocked } from '../../../store/MetaberrySlice/MetaberrySlice'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'

// TODO: Mover funcionalidad de MetaberryView a este hook
export function useMetaberryView() {
  const initialPathSchool = useSelector(
    (state) => state.configuration.pathSchool
  )
  const [pathAssetsSchool, setPathAssetsSchool] = useState()
  const [showFeedbackReport, setShowFeedbackReport] = useState(false)
  const [feedbackToast, setFeedbackToast] = useState(null)

  useEffect(() => {
    setPathAssetsSchool(
      initialPathSchool
        ? `/assets/graphics/gamification/${initialPathSchool}`
        : `/assets/graphics/gamification`
    )
  }, [initialPathSchool])

  // Rate Blueberry
  const handleRateBlueberryOpen = () => {
    store.dispatch(setIsGameKeyboardBlocked(true))
    setShowFeedbackReport(true)
  }

  const handleRateBlueberryClose = () => {
    setShowFeedbackReport(false)
    store.dispatch(setIsGameKeyboardBlocked(false))
  }

  const handleRateBlueberrySubmitSuccess = () => {
    if (window.innerWidth >= 520) {
      handleRateBlueberryClose()
    }
    setFeedbackToast({
      type: 'success',
      title: translate('popup-feedback-sent__title'),
      message: translate('popup-feedback-sent__description'),
      showCloseButton: true,
      autoClose: false
    })
  }

  const handleRateBlueberrySubmitError = () => {
    if (window.innerWidth >= 520) {
      handleRateBlueberryClose()
    }
    setFeedbackToast({
      type: 'error',
      title: translate('popup_error_activity_check_answer_title'),
      message: translate('popup_error_activity_check_answer_message_any'),
      showCloseButton: true,
      autoClose: false
    })
  }

  return {
    initialPathSchool,
    pathAssetsSchool,

    showFeedbackReport,
    handleRateBlueberryOpen,
    handleRateBlueberryClose,
    handleRateBlueberrySubmitSuccess,
    handleRateBlueberrySubmitError,

    feedbackToast,
    setFeedbackToast
  }
}
