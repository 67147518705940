import React, { useState, useEffect } from 'react'
import { ReactComponent as LightningBolt } from '../../../assets/img/metaberry_ui/lightning-bolt.svg'
import { ReactComponent as SoundBtn } from '../../../assets/img/metaberry_ui/sound.svg'
import { ReactComponent as GlobeIcon } from '../../../assets/img/metaberry_ui/globe.svg'
import { ReactComponent as LogoutIcon } from '../../../assets/img/metaberry_ui/logout.svg'
import whiteCloseIcon from '../../../assets/icons/x_white_icon.svg'
import Drawer from '../../atoms/Drawer/Drawer'
import Tooltip from '../../atoms/Tooltip/Tooltip'
import translate from '../../../i18n/translate'
import './menuDRawer.scss'
import { ReactComponent as Check } from '../../../assets/icons/check.svg'
import { setUserCustomLanguage } from '../../../services/userManagementService'
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup'
import MetaLogoutModal from '../MetaLogoutModal/MetaLogoutModal'
import { store } from '../../../store/configureStore'
import { setIsGameKeyboardBlocked } from '../../../store/MetaberrySlice/MetaberrySlice'
import AccordionMenu from '../AccordionMenu/AccordionMenu'

const MenuDrawer = ({
  isOpen,
  onCloseDrawer,
  playerUserName,
  playSoundEffect,
  handleToggleMusicConfig,
  musicOn,
  handleToggleEffectsConfig,
  effectsOn,
  pathAssetsSchool,
  userExperience,
  handleGoToAvatarSelector,
  onChangeMusicConfig,
  onChangeEffectsConfig,
  handleGoToAvatarSelectorMobile,
  setOptionsDrawerTab,
  optionsDrawerTab,
  setShowOptionsDrawer,
  showOptionsDrawer
}) => {
  const LANGUAGES = [
    { name: translate('user_custom_language_default'), code: 'default' },
    { name: translate('user_custom_language_es'), code: 'es' },
    { name: translate('user_custom_language_en'), code: 'en' },
    { name: translate('user_custom_language_pt'), code: 'pt' }
  ]

  const [languageState, setLanguageState] = useState({
    isDropdownOpen: false,
    languageToChange: LANGUAGES[0],
    selectedLanguage: LANGUAGES[0],
    isPopupOpen: false
  })

  const [menuState, setMenuState] = useState({
    showAvatarSelector: false,
    isSoundDropdownOpen: false,
    showLogoutModal: false
  })

  useEffect(() => {
    const currentCustomLanguage =
      window.localStorage.getItem('bb_custom_language') || 'default'
    const currentLanguage =
      LANGUAGES.find((lang) => lang.code === currentCustomLanguage) ||
      LANGUAGES[0]

    setLanguageState((prev) => ({
      ...prev,
      selectedLanguage: currentLanguage
    }))
  }, [])

  const handleLanguageChange = (newLanguage) => {
    setLanguageState((prev) => ({
      ...prev,
      languageToChange: newLanguage,
      isPopupOpen: true
    }))
  }

  const handleLanguageSelection = () => {
    setLanguageState((prev) => ({
      ...prev,
      selectedLanguage: languageState.languageToChange,
      isDropdownOpen: false
    }))
    setUserCustomLanguage(languageState.languageToChange.code)
  }

  const handleLogoutClick = () => {
    setMenuState((prev) => ({
      ...prev,
      showLogoutModal: true
    }))
  }

  const onLogoutClick = () => {
    setMenuState((prev) => ({
      ...prev,
      showLogoutModal: false
    }))
  }

  const handleAvatarSelectorClick = () => {
    playSoundEffect('controlPanel')
    onCloseDrawer()
    setOptionsDrawerTab(1)
    setShowOptionsDrawer(true)
    store.dispatch(setIsGameKeyboardBlocked(true))
  }

  const elementosMenuSonido = [
    <div className="meta-sound-menu__item" key="music">
      <label className="switch" onClick={() => playSoundEffect('uiToggle')}>
        <input
          type="checkbox"
          checked={musicOn}
          onChange={onChangeMusicConfig}
        />
        <span className="slider"></span>
        <span className="menu-drawer__options-text">
          {translate('music_label')}
        </span>
      </label>
    </div>,
    <div className="meta-sound-menu__item" key="effects">
      <label className="switch" onClick={() => playSoundEffect('uiToggle')}>
        <input
          type="checkbox"
          checked={effectsOn}
          onChange={onChangeEffectsConfig}
        />
        <span className="slider"></span>
        <span className="menu-drawer__options-text">
          {translate('effects_label')}
        </span>
      </label>
    </div>
  ]

  const elementosMenuIdioma = LANGUAGES.map((_language) => (
    <div
      key={_language.code}
      className="map-user-menu-modal__action-dropdown__option map-user-menu-modal__action-text"
      onClick={() => handleLanguageChange(_language)}
    >
      <span className="menu-drawer__options-text">{_language.name}</span>
      {languageState.selectedLanguage.code === _language.code && <Check />}
    </div>
  ))

  return (
    <div className="menu-drawer">
      <Drawer
        isOpen={isOpen}
        direction="right"
        onClose={onCloseDrawer}
        showCloseButton={false}
      >
        <div className="menu-drawer_background">
          <div className="menu-drawer_header-container">
            <div className="menu-drawer_header-name">
              <Tooltip message={playerUserName} position="bottom">
                <span>
                  {playerUserName.length > 16
                    ? `${playerUserName.substring(0, 16)}...`
                    : playerUserName}
                </span>
              </Tooltip>
            </div>
            <button
              className="menu-drawer_close-button"
              aria-label="Cerrar"
              onClick={() => {
                const drawerElement = document.querySelector('.drawer-box')
                drawerElement.classList.add('closing')
                drawerElement.addEventListener(
                  'animationend',
                  (e) => {
                    if (e.animationName.includes('slideOut')) {
                      onCloseDrawer()
                    }
                  },
                  { once: true }
                )
              }}
            >
              <img src={whiteCloseIcon} alt="Close menu drawer" />
            </button>
          </div>
          <div className="menu-drawer_options-background">
            <div className="menu-drawer_options-container">
              <div
                className="menu-drawer_option"
                onClick={handleAvatarSelectorClick}
              >
                <LightningBolt />
                <span className="menu-drawer__option-text">
                  {translate('user_custom_avatar_label')}
                </span>
              </div>

              <div className="menu-drawer_option">
                <AccordionMenu
                  header={translate('user_custom_sound_label')}
                  items={elementosMenuSonido}
                  icon={<SoundBtn />}
                />
              </div>

              <div className="menu-drawer_option">
                <AccordionMenu
                  header={translate('user_custom_language_label')}
                  items={elementosMenuIdioma}
                  icon={<GlobeIcon />}
                />
              </div>
              <div className="menu-drawer_separator"></div>
              <div
                className="menu-drawer_logout-container"
                onClick={handleLogoutClick}
              >
                <LogoutIcon />

                <div
                  className="menu-drawer_option-text"
                  data-testid="user_menu_exit_game"
                >
                  {translate('user_menu_exit_game')}
                </div>
              </div>
              {menuState.showLogoutModal && (
                <MetaLogoutModal onClose={onLogoutClick} />
              )}
            </div>
          </div>
        </div>
      </Drawer>

      {languageState.isPopupOpen && (
        <ConfirmPopup
          size="sm"
          acceptButtonText={translate('user_custom_language_accept')}
          onAcceptButtonClick={handleLanguageSelection}
          onClose={() =>
            setLanguageState((prev) => ({ ...prev, isPopupOpen: false }))
          }
        >
          <div className="custom-language-confirm-content">
            <div className="custom-language-confirm-title">
              {translate('user_custom_language_confirm_title', {
                language: languageState.languageToChange.name
              })}
            </div>

            <div className="custom-language-confirm-message">
              {translate('user_custom_language_confirm_message')}
            </div>
          </div>
        </ConfirmPopup>
      )}
    </div>
  )
}

export default MenuDrawer
