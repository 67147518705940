/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { usePhaserGame } from '../../../hooks/usePhaserGame'
import { useDispatch, useSelector } from 'react-redux'
import { GAME_CONFIGURATION } from './config'
import './metaberryView.scss'
import translate from '../../../i18n/translate'
import MetaOptionsMenuDrawer from '../../../modules/components/MetaOptionsMenuDrawer/MetaOptionsMenuDrawer'
import FeedbackReport from '../../components/FeedbackReport/FeedbackReport'
import MenuDrawer from '../../components/MenuDrawer/MenuDrawer'

import PopupManager from '../../components/PopupManager/PopupManager'
import usePopupManager from '../../components/PopupManager/usePopupManager'
import { setPetsNotification } from '../../../services/rewardsService'

import {
  setIsPracticing,
  setIsInQuizz,
  setAreLessonsUpdatedAfterPracticing,
  setIsGameKeyboardBlocked
} from '../../../store/MetaberrySlice/MetaberrySlice'

import {
  getMusicUserConfig,
  updateMusicUserConfig,
  getEffectsUserConfig,
  updateEffectsUserConfig,
  getDevMode,
  getSoundAssistanceApiConfig,
  setIsDailyGoalCompleted,
  isDailyGoalCompleted
} from '../../../services/settingsService'

import { store } from '../../../store/configureStore'
import { getCourseGuid } from '../../../assets/data/api'
import {
  createEndPoint,
  fetchWithToken,
  getToken
} from '../../../services/util'
import { setDailyGoalProgress } from '../../../store/DailyGoalSlice/DailyGoalSlice'
import { setUserExperienceProgress } from '../../../store/UserExperienceSlice/UserExperienceSlice'
import {
  setPiecesAccumulated,
  setCoinsAccumulated,
  setCoinsDailyGoal,
  setCoinsStreak,
  setStreakDay,
  setCoinsDailyGoalEarned
} from '../../../store/RewardsSlice/RewardsSlice'
import getLessons from './utils/getLessons'
import { updateUnitsAndLessons } from '../../../store/UnitsSlice/UnitsSlice'

import {
  updateDevMode,
  updateIsReadyGamification
} from '../../../store/Configuration/Configuration'

import { fetchLearningMap } from '../../../services/learningMapService'
import { setProgramId } from '../../../store/PracticeSlice/PracticeSlice'

import useSound from './hooks/useSound'
import ReactHowler from 'react-howler'
import unitMusicFile from '../../../assets/sound/little-apprentice-loop.mp3'
import lessonMusicFile from '../../../assets/sound/sun-shine-loop.mp3'
import introMusicFile from '../../../assets/sound/the-mountains-loop.mp3'

import { Analysis } from '../../../services/analysisService'
import {
  getAloneUserData,
  getFamilyUserData
} from '../../../services/userTypeService'
import AppPracticePage from '../../../pages/AppPracticePage/AppPracticePage'

import ModalTeacherPractice from '../../modals/ModalTeacherPractice/ModalTeacherPractice'
import useSocket from './hooks/useSocket'
import useQuiz from './hooks/useQuiz'

import LobbyTeacherPracticePage from '../../../pages/LobbyTeacherPracticePage/LobbyTeacherPracticePage'
import TeacherPracticePage from '../../../pages/TeacherPracticePage/TeacherPracticePage'
import ModalExitTeacherPractice from '../../modals/ModalTeacherPractice/ModalExitTeacherPractice'
import ModalScreenPortraitTeacherPractice from '../../modals/ModalTeacherPractice/ModalScreenPortraitTeacherPractice'
import MapScoreBar from '../../components/MapScoreBar/MapScoreBar'
import MapOptionsBar from '../../components/MapOptionsBar/MapOptionsBar'
import MapBottomBar from '../../components/MapBottomBar/MapBottomBar'
import {
  getAvatars,
  setAvatarsNotification
} from '../../../services/avatarService'
import { increaseCountProgress } from '../../../util/loadingProgressBar'
import useGamificationSocket from './hooks/useGamificationSocket'
import { useGamificationActivityContext } from '../../../context/GamificationActivityContext/useGamificationActivityContext'
import {
  setFirstBateryChargedPending,
  setFirstBrokenBateryPending,
  setFirstUnlockedLessonPending,
  setFirstUnlockedUnitPending,
  setFirstBateryCompletePending,
  setRewardBatteryUnlockPending,
  setLevelUpPending
} from '../../../store/PopupManagerSlice/PopupManagerSlice'
import setDailyAccessRewardHelper from '../../../store/RewardsSlice/helpers/setDailyAccessRewardHelper'

import BlueberryPopup from '../../components/StudentCourseProgressUnitDetail/components/BlueberryPopup/BlueberryPopup'
import BuildingProgressBlockedCard from '../../components/MetaTabBuildingProgress/components/BuildingProgressBlockedCard'
import BuildingProgressUnblockedCard from '../../components/MetaTabBuildingProgress/components/BuildingProgressUnblockedCard'
import UnitFrame from '../../components/StudentCourseProgressUnitDetail/components/UnitFrame/UnitFrame'
import CourseProgressContainer from '../../components/MetaTabBuildingProgress/components/CourseProgressContainer'
import { customStorage } from '../../../util/customStorage'
import { getUnitsTopRankedAndUserRanking } from '../../../services/unitService'
import { setUnitsTopRankedAndUserRanking } from '../../../store/RankingSlice/RankingSlice'
import { useMetaberryView } from './useMetaberryView'
import Tooltip from '../../atoms/Tooltip/Tooltip'
import Toast from '../../../pages/AppPracticePage/modules/components/Toast/Toast'

const USER_NAME_MAX_LENGTH = 20

const MetaberryView = () => {
  const { renderSoundFXController, playSoundEffect, toggleSoundEffects } =
    useSound()

  const { showIntroduction } = usePopupManager()

  const [playerUserName, setPlayerUserName] = useState('')
  const [playerGuid, setPlayerGuid] = useState('')
  const [userGuid, setUserGuid] = useState(null)

  const [playerEnteredActivity, setPlayerEnteredActivity] = useState(false)
  const [isLessonActive, setIsLessonActive] = useState(false)
  const [practiceData, setPracticeData] = useState(null)
  const dispatch = useDispatch()

  const isReadyGamification = useSelector(
    (state) => state.configuration.isReadyGamification
  )

  const unitsRankers = useSelector(
    (state) => state.ranking.unitsTopRankedAndUserRanking
  )

  // SOCKET GAMIFICACION
  useGamificationSocket(
    { userGuid: userGuid, isStudentGamified: true },
    isLessonActive
  )

  const { dailyGoalInProgress, setDailyGoalInProgress } =
    useGamificationActivityContext()

  const {
    firstUnlockedLessonPending,
    firstBrokenBateryPending,
    firstUnlockedUnitPending,
    firstBateryCompletePending,
    rewardBatteryUnlockPending,
    levelUpPending,
    firstBateryChargedPending
  } = useSelector((state) => state.popupManager)

  // QUIZ
  const handleExitTeacherPractice = async () => {
    store.dispatch(setIsInQuizz(false))
    handleExitAnyPractice()
  }
  const {
    dataPlayersTeacherPractice,
    isCreatedTeacherPractice,
    isInsideLobbyTeacherPractice,
    totalPlayersTeacherPractice,
    conectedPlayersTeacherPractice,
    isInsidePracticeTeacherPractice,
    contentQuiz,
    setInsideLobby,
    keyCountDown,
    countDownTeacherPractice,
    gameStartedHandle,
    onCloseModalTeacherPractice,
    statusMapPractice,
    statusPractice,
    numberQuestion,
    numberCorrectQuestion,
    showSolution,
    sendAnswerData,
    formatTime,
    timerQuestion,
    showModalExitPractice,
    onCloseTeacherPractice,
    userAvatarImg,
    isDeletePractice,
    goFullScreen,
    exitFullScreen,
    isFullscreen,
    isPlayerJoinGameStarted,
    langQuiz,
    userIsDemo,
    forcedQuizExit,
    setForcedQuizExit,
    onActivityEventQuiz,
    finishedTimeQuestion
  } = useQuiz(getToken, Analysis, handleExitTeacherPractice, playSoundEffect)

  const {
    initialPathSchool,
    pathAssetsSchool,
    showFeedbackReport,
    feedbackToast,
    setFeedbackToast,
    handleRateBlueberryOpen,
    handleRateBlueberryClose,
    handleRateBlueberrySubmitSuccess,
    handleRateBlueberrySubmitError
  } = useMetaberryView()

  const { disconnectSocket } = useSocket(setInsideLobby)

  useEffect(() => {
    if (forcedQuizExit) {
      onCloseTeacherPractice()
      handleExitTeacherPractice()
      Analysis.sendSegmentTrackEvent(Analysis.EVENT.PRACTICE_EXITED, {
        practice_id: contentQuiz.guid
      })
      setForcedQuizExit(false)
    }
  }, [forcedQuizExit])

  // Para control de back button en browser
  useEffect(() => {
    if (isLessonActive) {
      // Agrega una entrada a la historia del navegador
      window.history.pushState(null, null)

      // Escucha el evento popstate
      const handlePopState = (event) => {
        if (playerEnteredActivity) {
          event.preventDefault()
          handleExitStandarPractice()
        }
      }

      window.addEventListener('popstate', handlePopState)

      // Limpia el evento cuando se desmonte el componente
      return () => {
        window.removeEventListener('popstate', handlePopState)
      }
    }
  }, [isLessonActive, playerEnteredActivity])

  useEffect(() => {
    const initializeMetaberry = async () => {
      ocLog(window._getTestTime() + ' - MetaberryView tms-')
      increaseCountProgress()

      window.moveCharacterToTile = moveCharacterToTile
      checkDevMode()
      getSoundAssistanceApiConfig()

      const aloneMemberData = getAloneUserData()
      if (aloneMemberData) {
        setPlayerUserName(
          trimUserName(aloneMemberData.name, USER_NAME_MAX_LENGTH)
        )
        setPlayerGuid(aloneMemberData.guid)
        setUserGuid(aloneMemberData.guid)
      } else {
        const familyMemberData = getFamilyUserData()
        if (familyMemberData) {
          setPlayerUserName(
            trimUserName(familyMemberData.name, USER_NAME_MAX_LENGTH)
          )
          setPlayerGuid(familyMemberData.guid)
          setUserGuid(familyMemberData.guid)
        }
      }

      window.addEventListener('enter-unit', handleEnterUnit)
      window.addEventListener('enter-lesson', handleEnterLesson)
      window.addEventListener('keydown', handleHideMenus)
      document.addEventListener('visibilitychange', toogleSoundByVisibility)
      window.addEventListener('start-practice', handleStartStandarPractice)
      window.addEventListener('exit-activities', handleExitStandarPractice)
      window.addEventListener('game-started', gameStartedHandle)
    }

    initializeMetaberry()

    return () => {
      window.removeEventListener('enter-unit', handleEnterUnit)
      window.removeEventListener('enter-lesson', handleEnterLesson)
      window.removeEventListener('keydown', handleHideMenus)
      document.removeEventListener('visibilitychange', toogleSoundByVisibility)
      window.removeEventListener('start-practice', handleStartStandarPractice)
      window.removeEventListener('exit-activities', handleExitStandarPractice)
      window.removeEventListener('game-started', gameStartedHandle)
      disconnectSocket()
    }
  }, [])

  useEffect(() => {
    if (!isLessonActive && isGameUIVisible()) {
      if (dailyGoalInProgress) {
        const rewardDailyGoal = new CustomEvent('reward-daily-goal')
        setDailyGoalInProgress(false)
        window.dispatchEvent(rewardDailyGoal)
      }

      if (firstUnlockedLessonPending) {
        const onboardingLessonUnlocked = new CustomEvent(
          'onboarding-lesson-unlocked'
        )
        window.dispatchEvent(onboardingLessonUnlocked)
        store.dispatch(setFirstUnlockedLessonPending(false))
      }

      if (firstBrokenBateryPending) {
        const onboardingBrokenBattery = new CustomEvent(
          'onboarding-broken-battery'
        )
        window.dispatchEvent(onboardingBrokenBattery)
        store.dispatch(setFirstBrokenBateryPending(false))
      }

      if (firstUnlockedUnitPending) {
        const onboardingUnitUnlocked = new CustomEvent(
          'onboarding-unit-unlocked'
        )
        window.dispatchEvent(onboardingUnitUnlocked)
        store.dispatch(setFirstUnlockedUnitPending(false))
      }

      // Se comprueba si se ha subido de nivel durante el desafio para mostrar el popup
      if (levelUpPending) {
        const onLevelUpPending = new CustomEvent('user-level-up', {
          detail: { level: userExperience.level }
        })
        window.dispatchEvent(onLevelUpPending)
        store.dispatch(setLevelUpPending(false))
      }

      if (firstBateryCompletePending) {
        const onboardingBatteryComplete = new CustomEvent(
          'onboarding-complete-battery'
        )
        window.dispatchEvent(onboardingBatteryComplete)
        store.dispatch(setFirstBateryCompletePending(false))
      }

      if (rewardBatteryUnlockPending) {
        const onboardingRewardBatteryUnlock = new CustomEvent(
          'reward-battery-unlock'
        )
        window.dispatchEvent(onboardingRewardBatteryUnlock)
        store.dispatch(setRewardBatteryUnlockPending(false))
      }

      if (firstBateryChargedPending) {
        const onboardingBatteryCharged = new CustomEvent(
          'onboarding-charged-battery'
        )
        window.dispatchEvent(onboardingBatteryCharged)
        store.dispatch(setFirstBateryChargedPending(false))
      }
    }
  }, [
    isLessonActive,
    store.getState().metaberry.isGameUIVisible,
    firstUnlockedLessonPending,
    firstBrokenBateryPending,
    firstUnlockedUnitPending,
    firstBateryCompletePending,
    rewardBatteryUnlockPending,
    levelUpPending,
    firstBateryChargedPending
  ])

  const dailyProgress = useSelector((state) => ({
    progress: state.dailyGoal.progress,
    totalExp: state.dailyGoal.totalExp
  }))

  const userExperience = useSelector((state) => ({
    level: state.userExperience.level,
    progress: state.userExperience.progress,
    totalExperience: state.userExperience.totalExperience,
    previousExperience: state.userExperience.previousExperience
  }))

  const prevLevelRef = useRef(null)
  useEffect(() => {
    const onLevelChange = async () => {
      if (
        prevLevelRef.current !== null &&
        userExperience?.level &&
        prevLevelRef.current < userExperience.level
      ) {
        const resultPetsRewardsLevel = await checkPetsRewardsLevel(
          userExperience.level
        )

        if (resultPetsRewardsLevel) {
          setTimeout(() => {
            setPetsNotification(true)

            const currentState = store.getState()
            Analysis.sendSegmentTrackEvent(
              Analysis.SEGMENT_EVENTS['Pet Alert Notification Showed'],
              {
                current_level: currentState.userExperience.level,
                current_points: currentState.rewards.coinsAccumulated
              }
            )
          }, 150)
        }

        store.dispatch(setLevelUpPending(true))

        const resultAvatarsLevel = await checkAvatarsLevel(userExperience.level)
        if (resultAvatarsLevel) {
          setTimeout(() => {
            setAvatarsNotification(true)

            const currentState = store.getState()
            Analysis.sendSegmentTrackEvent(
              Analysis.SEGMENT_EVENTS['Avatar Notification Showed'],
              {
                current_level: currentState.userExperience.level,
                current_points: currentState.rewards.coinsAccumulated
              }
            )
          }, 150)
        }
      }

      prevLevelRef.current = userExperience.level
    }

    onLevelChange()
  }, [userExperience.level])

  async function getPetAvatarsList() {
    const endPoint = `blueberry/rewards-galleries`
    return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
  }

  async function checkPetsRewardsLevel(level) {
    const apiData = await getPetAvatarsList()
    const result = apiData.find((item) => {
      return parseInt(item.reward_level) === level
    })
    return result
  }

  async function checkAvatarsLevel(level) {
    const apiData = await getAvatars()
    const result = apiData.find((item) => {
      return parseInt(item.level) === level
    })
    return result
  }

  const rewards = useSelector((s) => ({
    piecesAccumulated: s.rewards.piecesAccumulated,
    coinsAccumulated: s.rewards.coinsAccumulated
  }))

  const gameRef = useRef(null)
  usePhaserGame(GAME_CONFIGURATION, gameRef)

  const trimUserName = (userName, maxLength) => {
    return userName.length <= maxLength
      ? userName
      : userName.substring(0, length - 1) + '…'
  }

  // Activa el modo developer/debug si es necesario
  const checkDevMode = () => {
    const blueberry_devmode = getDevMode()
    store.dispatch(updateDevMode({ isDevMode: blueberry_devmode }))
  }

  const updateMapData = async () => {
    const courseGuid = await getCourseGuid()

    const mapsEndPoint = `lms/courses/${courseGuid}/blueberry/maps`
    const courseData = await fetchWithToken(createEndPoint(mapsEndPoint)).then(
      (json) => json.data
    )

    store.dispatch(setProgramId(courseData.program_template_guid))

    console.log('courseData -- ', courseData)

    try {
      const infoUsersGamification = await fetchWithToken(
        createEndPoint('game-engine/info-users')
      )

      if (
        infoUsersGamification.status === 'success' &&
        infoUsersGamification.data
      ) {
        store.dispatch(updateIsReadyGamification(true))

        Analysis.sendEvent(Analysis.EVENT.EXPERIENCE, {
          user_experience: infoUsersGamification.data?.totalPoints || 0,
          session_experience:
            infoUsersGamification.data?.dailyGoal?.progress || 0
        })

        // Control para enviar evento dailyGoal a true sólo cuando corresponda
        const wasDailyGoalCompleted = isDailyGoalCompleted()
        const isDailyGoalCompletedNow =
          infoUsersGamification.data?.dailyGoal?.progress >=
          infoUsersGamification.data?.dailyGoal?.total
        const isDailyGoalCompletedEvent =
          !wasDailyGoalCompleted && isDailyGoalCompletedNow

        if (isDailyGoalCompletedEvent) {
          Analysis.sendEvent(Analysis.EVENT.DAILY_GOAL)
        }
        // Establecer estado ACTUAL 'daily goal' para comprobar al gananar experiencia si es o no la 1º vez que se llega a la necesaria de daily goal
        setIsDailyGoalCompleted(isDailyGoalCompletedNow)

        store.dispatch(
          setDailyGoalProgress({
            progress: infoUsersGamification.data?.dailyGoal?.progress || 0,
            totalExp: infoUsersGamification.data?.dailyGoal?.total || 0,
            canGetDailyReward: undefined,
            nextDailyReward: undefined,
            sessionId: courseData.session.id,
            sessionAttempId: courseData.session.attempt_id
          })
        )

        infoUsersGamification.data &&
          setDailyAccessRewardHelper(infoUsersGamification.data)

        store.dispatch(
          setUserExperienceProgress({
            level: infoUsersGamification.data?.order,
            next_level: infoUsersGamification.data?.pointsForNextLevel,
            previous_level: infoUsersGamification.data?.pointsForCurrentLevel,
            exp: infoUsersGamification.data?.totalPoints
          })
        )

        // Piezas acumuladas
        store.dispatch(
          setPiecesAccumulated(
            infoUsersGamification.data?.currencies?.find(
              (currency) => currency.currencyId === 2
            )?.amount || 0
          )
        )

        // Monedas acumuladas
        store.dispatch(
          setCoinsAccumulated(
            infoUsersGamification.data?.currencies?.find(
              (currency) => currency.currencyId === 1
            )?.amount || 0
          )
        )
      } else {
        store.dispatch(updateIsReadyGamification(false))
      }
    } catch (error) {
      console.error('Error al obtener información de gamificación:', error)
      store.dispatch(updateIsReadyGamification(false))
    }

    const unitGuid = store.getState().metaberry.unitGuid
    if (unitGuid) {
      const unitData = await fetchLearningMap()
      const lessons = await getLessons(unitGuid)

      store.dispatch(
        updateUnitsAndLessons({
          unit_guid: unitGuid,
          units: unitData,
          lessons: lessons
        })
      )

      const unitsGuid = store.getState()?.units?.map((unit) => unit.unit_guid)
      const unitsTopRankedAndUserRanking =
        await getUnitsTopRankedAndUserRanking(courseGuid, unitsGuid)
      store.dispatch(
        setUnitsTopRankedAndUserRanking(unitsTopRankedAndUserRanking)
      )

      store.dispatch(setAreLessonsUpdatedAfterPracticing(true))
    }
  }

  const [showMapIntro, setShowMapIntro] = useState(!showIntroduction())
  const [showOptionsDrawer, setShowOptionsDrawer] = useState(false)
  const [showMenuDrawer, setShowMenuDrawer] = useState(false)
  const [optionsDrawerTab, setOptionsDrawerTab] = useState(0)
  const [showCourseProgress, setShowCourseProgress] = useState(false)
  const [currentUnits, setCurrentUnits] = useState([])
  const [isUnitProgressDetailVisible, setIsUnitProgressDetailVisible] =
    useState(false)
  const [unitProgressDetail, setUnitProgressDetail] = useState(null)

  // Botones

  const onCloseMenu = () => {
    setOptionsDrawerTab(0)
    playSoundEffect('controlPanel')
    if (isMobile) {
      setShowMenuDrawer(false)
    } else {
      setShowOptionsDrawer(!showOptionsDrawer)
    }
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 520)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 520)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleGoToAvatarSelector = () => {
    playSoundEffect('controlPanel')
    if (isMobile) {
      setShowMenuDrawer(true)
      store.dispatch(setIsGameKeyboardBlocked(true))
    } else {
      setOptionsDrawerTab(1)
      setShowOptionsDrawer(!showOptionsDrawer)
      store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
    }
  }

  const handleGoToPetsSelector = () => {
    playSoundEffect('controlPanel')
    setOptionsDrawerTab(2)
    setShowOptionsDrawer(!showOptionsDrawer)
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  const handleOpenMissionsPanel = () => {
    playSoundEffect('controlPanel')
    setOptionsDrawerTab(3)
    setShowOptionsDrawer(!showOptionsDrawer)
    store.dispatch(setIsGameKeyboardBlocked(!showOptionsDrawer))
  }

  const handleCourseProgress = () => {
    playSoundEffect('controlPanel')
    setShowCourseProgress(!showCourseProgress)

    const units = store.getState().units
    if (units) {
      const unitsImages = store.getState().course.courseUnitsImages
      const _currentUnits = []
      for (let u = 0; u < units.length; u++) {
        _currentUnits.push({ ...units[u], image: unitsImages[u].image })
      }

      setCurrentUnits(_currentUnits)
    }
  }

  const handleUnitProgressDetail = async (unit, unitIndex) => {
    playSoundEffect('controlPanel')

    setShowCourseProgress(false)
    setIsUnitProgressDetailVisible(true)
    setUnitProgressDetail({
      index: unitIndex,
      name: unit.unit_name,
      guid: unit.unit_guid,
      completeLessonsTotal: unit.complete_lessons_total,
      lessonsTotal: unit.lessons_total,
      image: unit.image
    })
  }

  const enterUnitFromUnitDetail = (unit) => {
    store.dispatch(setIsGameKeyboardBlocked(false))
    setIsUnitProgressDetailVisible(false)

    window.dispatchEvent(
      new CustomEvent('enter-unit-from-react-component', {
        detail: {
          type: 'unit',
          unit_guid: unit.guid,
          unit_name: unit.name
        }
      })
    )
  }

  // Preferencias Sonido
  const MUSIC_VOLUME = 0.1
  const [musicOn, setMusicOn] = useState(getMusicUserConfig() === 1)
  const [effectsOn, setEffectsOn] = useState(getEffectsUserConfig() === 1)
  const [musicVolume, setMusicVolume] = useState(
    showIntroduction() ? MUSIC_VOLUME / 4 : MUSIC_VOLUME
  )
  const [musicFile, setMusicFile] = useState(
    showIntroduction() ? introMusicFile : unitMusicFile
  )
  const howlerMusicRef = useRef(null)

  const handleToggleMusicConfig = () => {
    const isMusicActive = getMusicUserConfig() === 1

    if (isMusicActive) {
      updateMusicUserConfig(0)
      setMusicOn(false)
    } else {
      updateMusicUserConfig(1)
      setMusicOn(true)
    }

    Analysis.sendEvent(
      isMusicActive ? Analysis.EVENT.MUSIC_OFF : Analysis.EVENT.MUSIC_ON
    )
  }

  const handleToggleEffectsConfig = () => {
    const isSoundsActive = getEffectsUserConfig() === 1

    if (isSoundsActive) {
      updateEffectsUserConfig(0)
      setEffectsOn(false)
    } else {
      updateEffectsUserConfig(1)
      setEffectsOn(true)
    }

    Analysis.sendEvent(
      isSoundsActive ? Analysis.EVENT.EFX_OFF : Analysis.EVENT.EFX_ON
    )
  }

  const toogleSoundByVisibility = () => {
    // Este función desactiva la música y sonidos al cambiar la visibilidad de la pestaña
    //  por lo que en caso de práctica o quiz se ignora
    const metaberryState = store.getState().metaberry

    if (!metaberryState.isPracticing && !metaberryState.isInQuizz) {
      const muteSounds = document.hidden

      const isMusicActive = getMusicUserConfig() === 1

      if (isMusicActive && muteSounds) {
        setMusicOn(false)
      } else if (isMusicActive && !muteSounds) {
        setMusicOn(true)
      }

      const isSoundsActive = getEffectsUserConfig() === 1

      if (isSoundsActive && muteSounds) {
        setEffectsOn(false)
      } else if (isSoundsActive && !muteSounds) {
        setEffectsOn(true)
      }
    }
  }

  const musicFadeOut = () => {
    const interval = 50 // 50ms interval
    let _musicVolume = showIntroduction() ? MUSIC_VOLUME / 4 : MUSIC_VOLUME
    const fadeoutInterval = setInterval(function () {
      if (_musicVolume > 0) {
        _musicVolume -= 0.01
        setMusicVolume(_musicVolume)
      } else {
        setMusicVolume(0)
        clearInterval(fadeoutInterval)
      }
    }, interval)
  }

  const musicFadeIn = () => {
    const interval = 50 // 50ms interval
    let _musicVolume = 0
    const maxMusicVolume = showIntroduction() ? MUSIC_VOLUME / 4 : MUSIC_VOLUME
    const fadeoutInterval = setInterval(function () {
      if (_musicVolume < maxMusicVolume) {
        _musicVolume += 0.01
        setMusicVolume(_musicVolume)
      } else {
        setMusicVolume(MUSIC_VOLUME)
        clearInterval(fadeoutInterval)
      }
    }, interval)
  }

  const handleEnterUnit = useCallback(() => {
    // ocLog('Entra a unidad')
    musicFadeOut()
    setTimeout(() => setMusicFile(unitMusicFile), 500)
  }, [])

  const handleEnterLesson = useCallback(() => {
    // ocLog('Entra a leccion')
    musicFadeOut()
    setTimeout(() => setMusicFile(lessonMusicFile), 500)
  }, [])

  const handleStartAnyPractice = async () => {
    store.dispatch(setIsGameKeyboardBlocked(true))

    if (getMusicUserConfig() === 1) {
      musicFadeOut()
      setTimeout(() => {
        setMusicOn(false)
      }, 1000)
    }
  }

  const handleExitAnyPractice = async () => {
    const metaberryState = store.getState().metaberry

    if (!metaberryState.isPracticing && !metaberryState.isInQuizz) {
      store.dispatch(setIsGameKeyboardBlocked(false))

      if (getMusicUserConfig() === 1) {
        setTimeout(() => {
          setMusicOn(true)
          musicFadeIn()
        }, 1000)
      }
    }
  }

  const handleStartStandarPractice = async (event) => {
    setPlayerEnteredActivity(true)
    setPracticeData(event.detail)
    setIsLessonActive(true)
    store.dispatch(setIsPracticing(true))
    handleStartAnyPractice()
  }

  const handleExitStandarPractice = useCallback(() => {
    setPlayerEnteredActivity(false)
    setPracticeData(null)
    setIsLessonActive(false)
    store.dispatch(setIsPracticing(false))
    handleExitAnyPractice()

    updateMapData().catch(console.error)
  }, [])

  const handleStartTeacherPractice = async () => {
    store.dispatch(setIsInQuizz(true))
    handleStartAnyPractice()
  }

  useEffect(() => {
    if (isLessonActive === true) {
      launchPractice()
    }
  }, [isLessonActive])

  const launchPractice = async () => {
    let ttsSettings = window.localStorage.getItem('bb_tts')
    ttsSettings = JSON.parse(ttsSettings)

    const practiceObject = {
      function: 'startPracticeWeb',
      params: {
        apiHost: createEndPoint(),
        userToken: getToken(),
        courseGuid: await getCourseGuid(),
        lesson: {
          unit_guid: practiceData.unit_guid,
          lesson_guid: practiceData.lesson_guid,
          challenges: practiceData.challenges,
          status: practiceData.status,
          pieces: {
            have: practiceData.pieces.have,
            pieces: practiceData.pieces.pieces
          }
        },
        language: ttsSettings.programLanguage,
        requiresAudioAssistance: ttsSettings.requiresSoundAssistance
      }
    }

    // ocLog('start pr ->\n lesson', practiceObject)
    window.postMessage(JSON.stringify(practiceObject))
  }

  const handleHideMenus = () => {
    // setShowSoundMenu(false)
  }

  const isPhaserVisible = () =>
    store.getState().metaberry.isPhaserVisible === true

  const isGameUIVisible = () =>
    store.getState().metaberry.isGameUIVisible === true

  // Función para mover un personaje a una tile concreta de cara a los test
  const moveCharacterToTile = (destinationTileX, destinationTileY) => {
    window.dispatchEvent(
      new CustomEvent('move-character-to-tile', {
        detail: {
          x: destinationTileX,
          y: destinationTileY
        }
      })
    )
  }

  const rateBlueberryComponent = (
    <Tooltip
      message={translate('info_tooltip_rate_blueberry')}
      position="right"
    >
      <img
        src={pathAssetsSchool + '/rating-blueberry.png'}
        alt="Rate Blueberry"
        onClick={handleRateBlueberryOpen}
      />
    </Tooltip>
  )

  return (
    <div
      className={
        'metaberry-view' +
        (!isPhaserVisible() && !isLessonActive
          ? ' metaberry-view__phaser_loading'
          : '')
      }
      data-testid="metaberry_view"
    >
      {/* MUSIC AND FX */}
      {renderSoundFXController()}
      <ReactHowler
        ref={howlerMusicRef}
        src={musicFile}
        playing={musicOn}
        volume={musicVolume}
        loop={true}
        onPlay={() => {
          musicFadeIn()
          // ocLog('Music playing', musicFile)
        }}
        onLoad={() => {}}
        onLoadError={() => console.error('Music load error')}
        onPlayError={(id, errorCode) =>
          console.error('Music play error: ', errorCode)
        }
      />
      {/* QUIZ */}
      <ModalTeacherPractice
        showModal={isCreatedTeacherPractice}
        startHandle={handleStartTeacherPractice}
        onCloseComplete={onCloseModalTeacherPractice}
        insideLobby={isInsideLobbyTeacherPractice}
        insidePractice={isInsidePracticeTeacherPractice}
        onCloseStart={() => {
          onCloseTeacherPractice()
          handleExitTeacherPractice()
        }}
        withCloseButton={userIsDemo}
      ></ModalTeacherPractice>
      <ModalScreenPortraitTeacherPractice
        showModal={isCreatedTeacherPractice}
      ></ModalScreenPortraitTeacherPractice>
      <LobbyTeacherPracticePage
        showPage={isInsideLobbyTeacherPractice}
        totalPlayers={totalPlayersTeacherPractice}
        conectedPlayers={conectedPlayersTeacherPractice}
        players={dataPlayersTeacherPractice}
        countDownTeacherPractice={countDownTeacherPractice}
        keyCountDown={keyCountDown}
        goFullScreen={goFullScreen}
        exitFullScreen={exitFullScreen}
        isFullscreen={isFullscreen}
      ></LobbyTeacherPracticePage>
      {isInsidePracticeTeacherPractice && (
        <TeacherPracticePage
          contentQuizz={contentQuiz}
          showPage={isInsidePracticeTeacherPractice}
          statusMapPractice={statusMapPractice}
          statusPractice={statusPractice}
          numberQuestion={numberQuestion}
          numberCorrectQuestion={numberCorrectQuestion}
          showSolution={showSolution}
          sendAnswerData={sendAnswerData}
          formatTime={formatTime}
          timerQuestion={timerQuestion}
          userAvatarImg={userAvatarImg}
          playerGuid={playerGuid}
          onExitPractice={() => {
            onCloseTeacherPractice()
            handleExitTeacherPractice()
            Analysis.sendSegmentTrackEvent(Analysis.EVENT.PRACTICE_EXITED, {
              practice_id: contentQuiz.guid
            })
          }}
          playSoundEffect={playSoundEffect}
          goFullScreen={goFullScreen}
          exitFullScreen={exitFullScreen}
          isFullscreen={isFullscreen}
          isPlayerJoinGameStarted={isPlayerJoinGameStarted}
          langQuiz={langQuiz}
          analysis={Analysis}
          onActivityEvent={onActivityEventQuiz}
          finishedTimeQuestion={finishedTimeQuestion}
          pathAssetsSchool={pathAssetsSchool}
        ></TeacherPracticePage>
      )}
      <ModalExitTeacherPractice
        showModal={
          Boolean(showModalExitPractice || isDeletePractice) &&
          statusPractice.phase !== statusMapPractice.IN_PODIUM
        }
        onCloseTeacherPractice={() => {
          onCloseTeacherPractice()
          handleExitTeacherPractice()
        }}
      ></ModalExitTeacherPractice>
      {/* GAME UI */}
      <div
        className={
          isLessonActive || !isGameUIVisible()
            ? ' metaberry-view__activity_on'
            : ''
        }
      >
        {isReadyGamification && (
          <MapScoreBar
            userExperience={userExperience}
            dailyProgress={dailyProgress}
            totalCoins={rewards.coinsAccumulated}
            totalPieces={rewards.piecesAccumulated}
            pathSchool={initialPathSchool}
          />
        )}

        <MapOptionsBar
          handleGoToAvatarSelector={handleGoToAvatarSelector}
          onCloseAvatarSelector={onCloseMenu}
          playerUserName={playerUserName}
          playSoundEffect={playSoundEffect}
          handleToggleMusicConfig={handleToggleMusicConfig}
          musicOn={musicOn}
          handleToggleEffectsConfig={handleToggleEffectsConfig}
          effectsOn={effectsOn}
          pathAssetsSchool={pathAssetsSchool}
        />

        <MapBottomBar
          handleOpenMissionsPanel={handleOpenMissionsPanel}
          handleGoToPetsSelector={handleGoToPetsSelector}
          handleCourseProgress={handleCourseProgress}
          isReadyGamification={isReadyGamification}
          rateBlueberryComponent={rateBlueberryComponent}
        />
      </div>
      {/* PHASER GAME */}
      <div
        id="phaser-game"
        data-sl="canvas-mq"
        className={
          'phaser-game' +
          (isLessonActive ? ' metaberry-view__activity_on' : '') +
          (!showMapIntro ? ' metaberry-view__intro_on' : '')
        }
        ref={gameRef}
      />

      <div className="metaberry-view__rate-blueberry">
        <Tooltip
          message={translate('info_tooltip_rate_blueberry')}
          position="right"
        >
          <img
            src={pathAssetsSchool + '/rating-blueberry.png'}
            alt="Rate Blueberry"
            onClick={handleRateBlueberryOpen}
          />
        </Tooltip>
      </div>
      {showFeedbackReport && (
        <FeedbackReport
          onClose={handleRateBlueberryClose}
          onSubmitSuccess={handleRateBlueberrySubmitSuccess}
          onSubmitError={handleRateBlueberrySubmitError}
          feedbackToast={feedbackToast}
          setFeedbackToast={setFeedbackToast}
        />
      )}
      {feedbackToast && window.innerWidth >= 768 && (
        <Toast
          data={feedbackToast}
          closeToast={() => setFeedbackToast(null)}
          showCloseButton={feedbackToast.showCloseButton}
          autoClose={feedbackToast.autoClose}
        />
      )}

      {/* Info Popups */}
      {!isLessonActive && isGameUIVisible() && (
        <PopupManager
          handleFinishIntro={() => {
            handleEnterUnit()
            setShowMapIntro(true)
          }}
          handleGoToPetsSelector={handleGoToPetsSelector}
        />
      )}
      {/* Avatars and pets modal */}
      {isMobile && showMenuDrawer ? (
        <MenuDrawer
          isOpen={showMenuDrawer}
          playerUserName={playerUserName}
          onCloseDrawer={onCloseMenu}
          pathAssetsSchool={pathAssetsSchool}
          playSoundEffect={playSoundEffect}
          onChangeEffectsConfig={handleToggleEffectsConfig}
          onChangeMusicConfig={handleToggleMusicConfig}
          musicOn={musicOn}
          effectsOn={effectsOn}
          optionsDrawerTab={optionsDrawerTab}
          setOptionsDrawerTab={setOptionsDrawerTab}
          setShowOptionsDrawer={setShowOptionsDrawer}
          showOptionsDrawer={showOptionsDrawer}
        />
      ) : (
        <MetaOptionsMenuDrawer
          showOptionsDrawer={showOptionsDrawer}
          onCloseDrawer={onCloseMenu}
          optionsDrawerTab={optionsDrawerTab}
          userExperience={userExperience}
        />
      )}

      {/* Activities view */}
      {isLessonActive && <AppPracticePage />}

      {showCourseProgress && (
        <BlueberryPopup
          title={translate('progress_map_title')}
          onClose={() => {
            setShowCourseProgress(false)
            customStorage.remove('course-progress-scroll')
          }}
        >
          <CourseProgressContainer>
            {currentUnits.map((unit, index) => {
              if (unit.is_available) {
                return (
                  <BuildingProgressUnblockedCard
                    key={index}
                    index={index + 1}
                    unit={unit}
                    position={
                      unitsRankers?.[index] ? unitsRankers[index].userRank : ''
                    }
                    onClick={() => {
                      handleUnitProgressDetail(unit, index + 1)
                    }}
                  />
                )
              } else {
                return (
                  <BuildingProgressBlockedCard
                    key={index}
                    index={index + 1}
                    title={unit.unit_name}
                  />
                )
              }
            })}
          </CourseProgressContainer>
        </BlueberryPopup>
      )}
      {isUnitProgressDetailVisible && (
        <BlueberryPopup
          title={translate('progress_map_title')}
          onClose={() => {
            setIsUnitProgressDetailVisible(false)
            customStorage.remove('course-progress-scroll')
          }}
        >
          <UnitFrame
            unit={unitProgressDetail}
            onGoBack={() => {
              setIsUnitProgressDetailVisible(false)
              handleCourseProgress()
            }}
            onEnterUnit={() => enterUnitFromUnitDetail(unitProgressDetail)}
          />
        </BlueberryPopup>
      )}
    </div>
  )
}

export default MetaberryView
