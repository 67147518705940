import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  unitsFullRanking: {},
  unitFullRankingLoading: false,
  unitFullRankingError: null,
  unitsTopRankedAndUserRanking: []
}

const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    setUnitFullRanking: (state, action) => {
      const { unitGuid, ranking } = action.payload
      state.unitsFullRanking[unitGuid] = ranking
    },

    setUnitFullRankingLoading: (state, action) => {
      state.unitFullRankingLoading = action.payload
    },

    setUnitFullRankingError: (state, action) => {
      state.unitFullRankingError = action.payload
    },

    setUnitsTopRankedAndUserRanking: (state, { payload }) => {
      state.unitsTopRankedAndUserRanking = payload
    },

    resetRankings: () => initialState
  }
})

export const {
  setUnitFullRanking,
  setUnitFullRankingLoading,
  setUnitFullRankingError,
  setUnitsTopRankedAndUserRanking,
  resetRankings
} = rankingSlice.actions

export default rankingSlice.reducer
