import { useState, useEffect, useCallback } from 'react'
import useMetaberryWithStore from '../../modules/views/MetaberryView/hooks/useMetaberryWithStore'
import { getUserAvatar } from '../../services/settingsService'
import { useSelector } from 'react-redux'
import useInternetConnection from '../../hooks/useInternetConnection'
import { Analysis } from '../../services/analysisService'
import { getDeviceType } from '../../util/devices'
import { store } from '../../store/configureStore'
import { setCurrentDeviceType } from '../../store/MetaberrySlice/MetaberrySlice'
import { debounce } from '../../util/debounce'

const useMetaBerryPage = () => {
  const { availableUnits } = useMetaberryWithStore()
  // ocLog('--- Metaberry PAGE availableUnits', availableUnits)

  const [userAvatarImg, setUserAvatarImg] = useState(
    getUserAvatar().navigation_icon_image
  )

  const [hasLoaded, setHasLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [progressBarCounter, setProgressBarCounter] = useState(0)

  const initialPathSchool = useSelector(
    (state) => state.configuration.pathSchool
  )
  const [pathSchool, setPathSchool] = useState(initialPathSchool)
  const [isLoadingPathSchool, setIsLoadingPathSchool] = useState(true)

  const connectionStatus = useInternetConnection()
  const nameSchool = useSelector((state) => state.configuration.nameSchool)

  useEffect(() => {
    setIsLoadingPathSchool(true)
    handleLoadingStarted()

    checkDeviceType()

    // Temporal - para el cambio de urls de tener # a no tener y ser llamadas desde APPs
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ function: 'isNotPractice' })
      )
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Map Page'],
      {
        device_type: getDeviceType(),
        is_tablet: getDeviceType() === 'tablet',
        is_mobile: getDeviceType() === 'phone',
        resolution: window.innerWidth + 'x' + window.innerHeight,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio,
        user_agent: window?.navigator?.userAgent
      }
    )

    window.addEventListener('player-avatar-change', updateAvatar)
    window.addEventListener('loading-started', handleLoadingStarted)
    window.addEventListener('loading-finished', handleLoadingFinished)
    window.addEventListener('increase-progress-bar', handleProgressBar)
    window.addEventListener('game-started', changeInitialLoadStatus)
    window.addEventListener('resize', debouncedCheckDeviceType)

    return () => {
      window.removeEventListener('player-avatar-change', updateAvatar)
      window.removeEventListener('loading-started', handleLoadingStarted)
      window.removeEventListener('loading-finished', handleLoadingFinished)
      window.removeEventListener('increase-progress-bar', handleProgressBar)
      window.removeEventListener('game-started', changeInitialLoadStatus)
      window.removeEventListener('resize', debouncedCheckDeviceType)
    }
  }, [])

  useEffect(() => {
    setPathSchool(initialPathSchool)
    const timeoutId = setTimeout(() => {
      setIsLoadingPathSchool(false)
    }, 500)

    // Función de limpieza para cancelar el timeout si el componente se desmonta
    return () => {
      clearTimeout(timeoutId)
    }
  }, [initialPathSchool])

  const changeInitialLoadStatus = () => {
    setHasLoaded(true)
  }

  const updateAvatar = () => {
    setUserAvatarImg(getUserAvatar().navigation_icon_image)
  }

  const handleLoadingStarted = () => {
    if (!isLoading) {
      setIsLoading(true)
    }
  }

  const handleLoadingFinished = () => {
    setIsLoading(false)
  }

  const handleProgressBar = (event) => {
    setProgressBarCounter(event.detail.count)
  }

  const checkDeviceType = () => {
    store.dispatch(setCurrentDeviceType(getDeviceType()))
  }

  const debouncedCheckDeviceType = useCallback(
    debounce(checkDeviceType, 250),
    []
  )

  return {
    availableUnits,
    connectionStatus,
    nameSchool,
    isLoading,
    hasLoaded,
    userAvatarImg,
    progressBarCounter,
    pathSchool,
    isLoadingPathSchool
  }
}

export default useMetaBerryPage
