import { useEffect, useMemo, useRef, useState } from 'react'
import io from 'socket.io-client'
import { store } from '../../../../store/configureStore'
import { setUserExperienceProgress } from '../../../../store/UserExperienceSlice/UserExperienceSlice'
import { useGamificationActivityContext } from '../../../../context/GamificationActivityContext/useGamificationActivityContext'
import { CURRENCY_TYPES } from '../../../../config/constants/GamificationConstants'

const useGamificationSocket = (
  { userGuid, isStudentGamified },
  isLessonActive
) => {
  const {
    setPointsRewards,
    setCurrencyCoinRewards,
    setCurrencyPieceRewards,
    setDailyGoalInProgress
  } = useGamificationActivityContext()

  const [notifications, setNotifications] = useState([])
  const isLessonActiveRef = useRef(isLessonActive)
  const rewardDailyGoal = new CustomEvent('reward-daily-goal')

  const gameEngineApiURL = useMemo(() => {
    if (window.organizationData?.gameEngine) {
      const url = new URL(window.organizationData?.gameEngine)
      return `${url.protocol}//${url.hostname}`
    }
    // fallback
    const hostname = window.location.hostname
    return `${window.location.protocol}//${hostname.replace(
      'school',
      'game-eng'
    )}`
  }, [])

  function getPathSocket() {
    return window.isProductionEnvironment
      ? '/api/socket.io/'
      : '/game-eng/socket.io/'
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (notifications.length > 0) {
        const [...remainingNotifications] = notifications
        setNotifications(remainingNotifications)
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [notifications])

  useEffect(() => {
    console.log('useGamificationSocket isLessonActive -- ', isLessonActive)
    isLessonActiveRef.current = isLessonActive
  }, [isLessonActive])

  useEffect(() => {
    console.log('GAMIFICACION userGuid --- ', userGuid)
    let socket = null
    if (userGuid && isStudentGamified) {
      socket = io(gameEngineApiURL, {
        path: getPathSocket(),
        transports: ['websocket']
      })
      socket.on('connect', () => {
        console.log('Connected to WebSocket server', userGuid)
        // Unirse a la sala del usuario, por ejemplo, utilizando el userId
        socket.emit('joinRoom', userGuid)
      })

      socket.on('pointsReward', (reward) => {
        console.log('Points reward received:', reward)

        setPointsRewards(reward.points)
      })

      socket.on('currencyReward', (reward) => {
        console.log('Currency reward received:', reward)
        // Solo guardar si las currency son de tipo event no de tipo rule
        if (reward.triggerType === 'event') {
          if (reward.currencyId === CURRENCY_TYPES.COINS) {
            setCurrencyCoinRewards(reward.amount)
          } else if (reward.currencyId === CURRENCY_TYPES.PIECES) {
            setCurrencyPieceRewards(reward.amount)
          }
        }
      })

      socket.on('mainDailyGoalAchieved', (dailyGoal) => {
        console.log('Daily Goal received:', dailyGoal)

        // Guardar los datos en el context para cuando se muestre el popup esten disponibles

        if (isLessonActiveRef.current) {
          setDailyGoalInProgress(true)
        } else {
          window.dispatchEvent(rewardDailyGoal)
        }
      })

      socket.on('levelUp', (newLevel) => {
        const dataLevel = {
          level: newLevel.currentLevel.order,
          next_level: newLevel.nextLevel.pointsRequired,
          previous_level: newLevel.currentLevel.pointsRequired,
          exp: newLevel.currentPoints
        }

        store.dispatch(setUserExperienceProgress(dataLevel))
      })
    }

    return () => {
      if (userGuid && socket) {
        socket.off('connect')
        socket.off('pointsReward')
        socket.off('badgeReward')
        socket.off('currencyReward')
        socket.off('levelUp')
        socket.close()
      }
    }
  }, [userGuid, isStudentGamified])

  return {}
}

export default useGamificationSocket
