import React from 'react'
import MetaberryView from '../../modules/views/MetaberryView/MetaberryView'
import './MetaBerryPage.scss'
import QuizLoading from '../TeacherPracticePage/components/QuizLoading/QuizLoading'
import LoadingPage from '../LoadingPage/LoadingPage'
import InternetConnectionAlert from '../../modules/components/InternetConnectionAlert/InternetConnectionAlert'
import useMetaBerryPage from './useMetaBerryPage'

const MetaberryPage = () => {
  const {
    availableUnits,
    connectionStatus,
    nameSchool,
    isLoading,
    hasLoaded,
    userAvatarImg,
    progressBarCounter,
    pathSchool,
    isLoadingPathSchool
  } = useMetaBerryPage()

  return (
    <div className="metaberry-page" data-testid="metaberry_page">
      <InternetConnectionAlert
        connectionStatus={connectionStatus}
        school_name={nameSchool}
      />
      {isLoading && (
        <div className="metaberry-loading" data-testid="metaberry_loading">
          {hasLoaded ? (
            <QuizLoading
              message=""
              goFullScreen={() => {}}
              exitFullScreen={() => {}}
              isFullscreen={true}
              isPlayerJoinGameStarted={false}
              userAvatarImg={userAvatarImg}
            />
          ) : (
            <LoadingPage
              count={progressBarCounter}
              pathSchool={pathSchool}
              isLoadingPathSchool={isLoadingPathSchool}
            />
          )}
        </div>
      )}

      {availableUnits.length > 0 && <MetaberryView />}
    </div>
  )
}

export default MetaberryPage
