import React from 'react'
import PropTypes from 'prop-types'
import './tooltip.scss'

const Tooltip = ({ message, position = 'top', children }) => {
  return (
    <div className={`tooltip tooltip--${position}`}>
      {children}
      <div className={`tooltip__message tooltip__message--${position}`}>
        {message}
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.node.isRequired
}
export default Tooltip
