import React, { useEffect, useState, useRef } from 'react'
import MetaRanking from './components/MetaRanking/MetaRanking'
import { ReactComponent as LockIcon } from '../../../assets/img/metaberry_ui/lock.svg'
import translate from '../../../i18n/translate'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../services/analysisService'
import { getRankingGamification } from '../../../assets/data/api'
import { store } from '../../../store/configureStore'
import {
  setUnitFullRanking,
  setUnitFullRankingLoading,
  setUnitFullRankingError
} from '../../../store/RankingSlice/RankingSlice'
import TitleContainer from '../../atoms/TitleContainer/TitleContainer'

const MetaUnitPanel = ({
  isAvailable,
  unitName,
  previousUnitImage,
  ranking,
  userGuid,
  unitGuid,
  previousUnitGuid,
  previousUnitName,
  handleEnterButton,
  handleWhereButton,
  currentTileSize,
  playerScreenPosition,
  isRankingShowed,
  pathSchool,
  courseGuid,
  isFixed // Indica que no tiene que moverse o ajustarse con el personaje o el tamaño de ventana
}) => {
  const [BackgroundCircleImage] = useState(
    `${process.env.PUBLIC_URL}/assets/graphics/images/background-gradient-circle.svg`
  )

  const isReadyGamification = store.getState().configuration.isReadyGamification

  const [rankingState, setRankingState] = useState([])

  const refContainer = useRef()

  const [panelStyle, setPanelStyle] = useState({
    top: 0,
    left: 0,
    visibility: 'hidden',
    transition: 'none'
  })

  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    const rankings = store.getState().ranking.unitsFullRanking
    const rankingByUnitGuid = rankings[unitGuid]
    if (rankingByUnitGuid) {
      setRankingState(rankingByUnitGuid)
      getRankingGameEngine()
    } else {
      getRankingGameEngine()
    }
  }, [])

  useEffect(() => {
    playSoundEffect('portalInfo')

    if (!isFixed) {
      // Calcular posición del panel en base al personaje y la batería activa
      const gameContainer = document.querySelector('#phaser-game')
      const thisPanel = refContainer.current

      // Asegurar que se puede modificar
      let panelX = Math.round(window.outerWidth / 2)
      let panelY = Math.round(window.outerHeight / 2)
      if (gameContainer && thisPanel) {
        const gameRect = gameContainer.getBoundingClientRect()

        const panelWidth = thisPanel.offsetWidth
        const panelHeight = thisPanel.offsetHeight

        panelX = getPanelX(
          gameRect,
          playerScreenPosition,
          panelWidth,
          currentTileSize
        )

        panelY = getPanelY(
          gameRect,
          playerScreenPosition,
          panelHeight,
          currentTileSize
        )
      }

      setPanelStyle({
        top: panelY,
        left: panelX,
        visibility: 'visible',
        transition: 'none'
      })

      document.addEventListener('player-moved', updatePosition)
    }

    return () => {
      if (!isFixed) document.removeEventListener('player-moved', updatePosition)
    }
  }, [])

  function isPlayerInPanelArea_Horizontal(
    playerLeftX,
    playerRightX,
    areaLeft,
    areaRight
  ) {
    const isOverlaped =
      areaLeft - 2 < playerRightX && playerLeftX < areaRight + 2

    return isOverlaped
  }

  function getPanelX(gameRect, playerPosition, panelWidth, tileSize) {
    // Posibles posiciones
    // 1 Derecha      2 Izquierda      3 Menor de los males
    const halfTile = Math.round(tileSize * 0.5)
    const panelXBaseRight = playerPosition.x + tileSize + halfTile
    const panelXBaseLeft = playerPosition.x - panelWidth - halfTile
    const playerLeftX = playerPosition.x - halfTile
    const playerRightX = playerPosition.x + halfTile

    // Se comprueba si cabe en el lado derecho
    let panelLeft = panelXBaseRight
    let panelRight = panelLeft + panelWidth

    if (
      panelRight + tileSize < gameRect.width &&
      !isPlayerInPanelArea_Horizontal(
        playerLeftX,
        playerRightX,
        panelLeft,
        panelRight
      )
    ) {
      return panelLeft
    } else {
      // Se comprueba si cabe en el lado izquierdo
      panelLeft = panelXBaseLeft
      panelRight = panelLeft + panelWidth

      if (
        panelLeft >= tileSize &&
        !isPlayerInPanelArea_Horizontal(
          playerLeftX,
          playerRightX,
          panelLeft,
          panelRight
        )
      ) {
        return panelLeft
      } else {
        // Si es obligatorio se escoge la mejor de las peores opciones

        if (gameRect.width - playerRightX >= playerLeftX) {
          return panelXBaseRight
        } else {
          return panelXBaseLeft + halfTile
        }
      }
    }
  }

  function getPanelY(gameRect, playerPosition, panelHeight, tileSize) {
    const halfTile = Math.round(tileSize * 0.5)

    let panelY = playerPosition.y - halfTile - Math.round(panelHeight / 2)

    // Se comprueba si se sale por arriba (con 1 tile de margen), sino se baja
    if (panelY < tileSize) {
      panelY = tileSize
    }

    // Se comprueba si se sale por debajo, sino se sube
    const overflowY = gameRect.height - (panelY + panelHeight + tileSize)
    if (overflowY < 0) {
      panelY += overflowY
    }

    return panelY
  }

  // eslint-disable-next-line no-unused-vars
  function updatePosition(event) {
    if (!isFixed) {
      const playerPosition = event.detail.playerPosition
      const tileSize = event.detail.currentTileSize

      // Calcular posición del panel en base al personaje y la batería activa
      const gameContainer = document.querySelector('#phaser-game')
      const thisPanel = refContainer.current

      if (gameContainer && thisPanel) {
        const gameRect = gameContainer.getBoundingClientRect()
        const panelWidth = thisPanel.offsetWidth
        const panelHeight = thisPanel.offsetHeight

        const panelX = getPanelX(gameRect, playerPosition, panelWidth, tileSize)
        const panelY = getPanelY(
          gameRect,
          playerPosition,
          panelHeight,
          tileSize
        )

        if (panelStyle.left !== panelX || panelStyle.top !== panelY) {
          setPanelStyle({
            top: panelY,
            left: panelX,
            visibility: 'visible',
            transition: 'top 500ms, left 500ms'
          })
        }
      }
    }
  }

  async function getRankingGameEngine() {
    store.dispatch(setUnitFullRankingLoading(true))
    try {
      const rankingGameEngine = await getRankingGamification(
        courseGuid,
        unitGuid,
        'historical'
      )
      store.dispatch(
        setUnitFullRanking({ unitGuid, ranking: rankingGameEngine })
      )
      setRankingState(rankingGameEngine)
    } catch (error) {
      console.error('Error al obtener el ranking:', error)
      store.dispatch(setUnitFullRankingError(error.message))
    } finally {
      store.dispatch(setUnitFullRankingLoading(false))
    }
  }

  function handleWhereInner() {
    const whereUnitEventObject = {
      program_id: store.getState().practice.programId,
      course_id: courseGuid,
      unit_id: unitGuid,
      unit_name: unitName
    }

    if (previousUnitGuid) {
      whereUnitEventObject.where_unit_id = previousUnitGuid
      whereUnitEventObject.where_unit_name = previousUnitName
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Unit Where Button Clicked'],
      whereUnitEventObject
    )

    handleWhereButton()
  }

  return (
    <div
      className={'meta-unit-panel phaser-dom'}
      style={panelStyle}
      ref={refContainer}
      data-fixed={isFixed}
    >
      <div
        className="meta-unit-panel_decoration"
        style={{ backgroundImage: `url(${BackgroundCircleImage})` }}
      ></div>
      {renderSoundFXController()}
      {!isAvailable && (
        <div className="meta-unit-panel__blocked">
          <div className="meta-unit-panel__blocked-icon">
            <LockIcon />
          </div>
          <div className="meta-unit-panel__blocked-title">
            {translate('unit_blocked')}
          </div>
          <div className="meta-unit-panel__blocked-info">
            {translate('unit_blocked_info')}
          </div>
          {previousUnitImage && (
            <div className="meta-unit-panel__previous-image">
              <img src={previousUnitImage} />
            </div>
          )}
          <div
            className="meta-unit-panel__button"
            onClick={() => {
              playSoundEffect('uiButton')
              setTimeout(() => handleWhereInner(), 70)
            }}
            data-testid="unit_enter_button"
          >
            {translate('unit_previous_where')}
          </div>
        </div>
      )}

      {!!isAvailable && <TitleContainer titleText={unitName} />}
      {!!isAvailable && isRankingShowed && isReadyGamification && (
        <div className="meta-unit-panel__ranking">
          <MetaRanking
            ranking={rankingState || []}
            currentUser={userGuid}
            unitGuid={unitGuid}
            unitName={unitName}
            pathSchool={pathSchool}
            loadingRanking={
              store.getState().ranking.unitFullRankingLoading || false
            }
            isGamification={true}
          />
        </div>
      )}

      {!!isAvailable && !isRankingShowed && <br />}
      {!!isAvailable && (
        <div
          className={`meta-unit-panel__button ${
            !isReadyGamification ? 'meta-unit-panel__button-mt' : ''
          }`}
          onClick={() => {
            playSoundEffect('uiButton')
            setTimeout(() => handleEnterButton(), 70)
          }}
          data-testid="unit_enter_button"
        >
          {translate('unit_enter')}
        </div>
      )}
    </div>
  )
}

export default MetaUnitPanel
