import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './accordionMenu.scss'

const AccordionMenu = ({ header, items, icon }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={`accordion-menu ${isOpen ? 'open' : ''}`}>
      <div
        className="accordion-header menu-drawer_option"
        onClick={() => setIsOpen(!isOpen)}
      >
        {icon}
        {header}
      </div>
      {isOpen && (
        <ul className="accordion-list">
          {items.map((item, index) => (
            <li key={index} className="accordion-item">
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

AccordionMenu.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  icon: PropTypes.node
}

AccordionMenu.defaultProps = {
  header: 'Menú',
  items: []
}

export default AccordionMenu
