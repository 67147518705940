import { createEndPoint, getToken } from './util'

export const sendUserRating = async ({ rating, feedback }) => {
  const endPoint = createEndPoint('blueberry/notifications/feedback')

  return fetch(endPoint, {
    method: 'POST',
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      rating,
      feedback
    })
  })
    .then((response) => response.json())
    .then((json) => {
      const { status, data } = json
      if (status !== 'success') throw json

      return data
    })
    .catch((error) => {
      return { error: error }
    })
}
