import React, { useEffect, useState } from 'react'
import './title.scss'

function Title({ text, onClassChange }) {
  const [cssClass, setCssClass] = useState('boldS')

  useEffect(() => {
    let newCssClass = 'boldS'
    if (text) {
      if (text.length < 20) {
        newCssClass = 'boldL'
      } else if (text.length < 31) {
        newCssClass = 'boldXL'
      } else if (text.length < 40) {
        newCssClass = 'boldM'
      } else {
        newCssClass = 'boldS'
      }
    }
    setCssClass(newCssClass)

    if (onClassChange) {
      onClassChange(newCssClass)
    }
  }, [text, onClassChange])

  return <div className={cssClass}>{text}</div>
}

export default Title
