import React, { useState, useEffect } from 'react'
import './selectRegion.scss'
import iconArrow from '../../../../assets/icons/arrows-up-down.svg'
import translate from '../../../../i18n/translate'
import apiService from '../../../../services/util/apiService'
import { customStorage } from '../../../../util/customStorage'
import {
  REGION_GUESSER_URL,
  REGIONS_DATA,
  NORTH_AMERICA_COUNTRY_CODES,
  CENTRAL_AMERICA_COUNTRY_CODES,
  SOUTH_AMERICA_COUNTRY_CODES
} from '../../../../config/constants/regionsConstants'
import ModalWithTimer from '../../../../modules/modals/ModalWithTimer/ModalWithTimer'
import { Analysis } from '../../../../services/analysisService'

const SelectRegion = ({ setParentRegion }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRegionData, setSelectedRegionData] = useState(null) // Nuevo estado para selectedRegionData
  const [suitableRegion, setSuitableRegion] = useState(null)
  const [isRegionDialogVisible, setIsRegionDialogVisible] = useState(false)
  const [regionsData] = useState(
    [...REGIONS_DATA].map((region) => ({
      ...region,
      label: translate('select_region_option_' + region.value)
    }))
  )

  useEffect(() => {
    checkUserRegion()
  }, [])

  const checkUserRegion = async () => {
    const userRegionAllowed = customStorage.get('bb_user_region_allowed')

    if (userRegionAllowed) {
      setSelectedRegionData(userRegionAllowed)
      setParentRegion(userRegionAllowed)
    } else {
      // Se obtine el dominio adecuado al usuario
      const _suitableRegion = await getRegionUser()
      if (!_suitableRegion?.error) {
        setSuitableRegion(_suitableRegion)
      }

      // Se obtienen las coincidencias según el dominio de al URL
      const domain = window.location.hostname
      let domainMatch = []
      if (
        domain.includes('localhost') ||
        domain.includes('blueberry-dev') ||
        domain.includes('blueberry-qa')
      ) {
        domainMatch.push(regionsData[0])
      } else {
        domainMatch = regionsData.filter((r) => domain.endsWith(r.endsWith))
      }
      // Si se encuentra más de un resultado es que estamos en un dominio compartido entre varios paises
      // Y tenemos que localizar al usuario para saber que opción le ponemos
      const isSharedDomain = domainMatch && domainMatch.length > 1
      const userRegion = isSharedDomain
        ? domainMatch.find((r) => r.value === _suitableRegion?.value) ||
          domainMatch[0]
        : domainMatch[0]

      setSelectedRegionData(userRegion)
      setParentRegion(userRegion)

      if (
        !_suitableRegion?.error &&
        _suitableRegion.value !== userRegion.value
      ) {
        // Si la región más adecuada es distinta a la seleccionada, se comprueban la URLs
        // si son iguales sólo se cambia la seleccionda, sino se propone redireccionar
        if (_suitableRegion.url === userRegion.url) {
          setSelectedRegionData(_suitableRegion)
          setParentRegion(_suitableRegion)
        } else {
          setIsRegionDialogVisible(true)
        }
      }
    }
  }

  const getRegionUser = async () => {
    try {
      const regionUser = await apiService.fetchGlobal(REGION_GUESSER_URL)

      const countryCode = regionUser?.country
      let match = regionsData[0] // EU

      if (countryCode === 'BR') {
        match = regionsData.find((r) => r.value === 'br')
      } else if (countryCode === 'DO') {
        match = regionsData.find((r) => r.value === 'do')
      } else if (
        countryCode === 'MX' ||
        NORTH_AMERICA_COUNTRY_CODES.includes(countryCode) ||
        CENTRAL_AMERICA_COUNTRY_CODES.includes(countryCode)
      ) {
        match = regionsData.find((r) => r.value === 'mx')
      } else if (SOUTH_AMERICA_COUNTRY_CODES.includes(countryCode)) {
        match = regionsData.find((r) => r.value === 'sa')
      }

      return match
    } catch (error) {
      console.error('getRegionUser error-- ', error)
      return { error: 'Error getting user region' }
    }
  }

  const handleChange = (selectedRegion) => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Log In Select Region Changed'],
      {
        region_selected: selectedRegion.value,
        region_name: selectedRegion.label,
        tenant: selectedRegion.url
      }
    )

    const newRegion = regionsData.find((r) => r.value === selectedRegion)
    setSelectedRegionData(newRegion)
    setParentRegion(newRegion)

    // Si el actual usuario tiene como dominio al mismo que tiene que ir no se redirige se le cambia solo el selector
    const fullDomain = window.location.protocol + '//' + window.location.host
    if (fullDomain !== newRegion.url) {
      window.location.href = newRegion.url
    }

    setIsOpen(false)
  }

  return (
    <>
      <div className="region-select-container">
        <label className="region-select-label">
          {translate('select_region_label')}
        </label>
        <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
          <div className="selected-option">
            <img src={iconArrow} alt="" className="arrowIcon" />
            {selectedRegionData ? (
              <>
                <img
                  src={selectedRegionData.flag}
                  alt={selectedRegionData.label}
                  className="flag"
                />
                {selectedRegionData.label}
              </>
            ) : (
              ''
            )}
          </div>
          {isOpen && (
            <ul className="options-list">
              {regionsData.map((region) => (
                <li
                  key={region.value}
                  onClick={() => handleChange(region.value)}
                  className="option"
                >
                  <img src={region.flag} alt={region.label} className="flag" />
                  {region.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {isRegionDialogVisible && (
        <ModalWithTimer
          titleTextCode={'modal_region_title'}
          titleTextProperties={suitableRegion}
          messageTextCode={'modal_region_message'}
          countdownTextCodeStart={'modal_region_countdown_text_start'}
          countdownTextCodeEnd={'modal_region_countdown_text_end'}
          countdownSeconds={10}
          acceptTextCode={'modal_region_accept'}
          onAccept={() => {
            window.location.href = suitableRegion.url
          }}
          cancelTextCode={'modal_region_cancel'}
          onCancel={() => setIsRegionDialogVisible(false)}
        />
      )}
    </>
  )
}

export default SelectRegion
