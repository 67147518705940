export const CURRENCY_TYPES = {
  PIECES: 2,
  COINS: 1
}

export const GAMIFICATION_ACTIONS = {
  SET_POINTS_REWARDS: 'SET_POINTS_REWARDS',
  SET_CURRENCY_COIN_REWARDS: 'SET_CURRENCY_COIN_REWARDS',
  SET_CURRENCY_PIECES_REWARDS: 'SET_CURRENCY_PIECES_REWARDS',
  RESET: 'RESET',
  SET_DAILY_GOAL_IN_PROGRESS: 'SET_DAILY_GOAL_IN_PROGRESS'
}
