import posthog from 'posthog-js'

export const FEATURE_FLAGS = {
  ENABLE_GAMIFICATION_ENGINE: 'enableGamificationEngine',
  ENABLE_PWA_PROMPT: 'enablePWAPrompt'
}

export default function isFeatureEnabled(flag) {
  if (flag === FEATURE_FLAGS.ENABLE_GAMIFICATION_ENGINE) return true
  return posthog?.isFeatureEnabled(flag)
}

export function waitForFeatureFlag(flag, action) {
  posthog.onFeatureFlags(() => {
    const isEnabled = posthog?.isFeatureEnabled(flag)
    action?.(isEnabled)
  })
}
