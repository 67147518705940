import React, { useState } from 'react'
import './drawer.scss'
import { ReactComponent as CloseIconColor } from '../../../assets/icons/purple_close_icon.svg'

const Drawer = ({
  children,
  onClose,
  direction = 'right',
  showCloseButton = true
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startPos, setStartPos] = useState(0)
  const [currentPos, setCurrentPos] = useState(0)
  const [isClosing, setIsClosing] = useState(false)

  const handleAnimationEnd = (e) => {
    if (isClosing && e.animationName.includes('slideOut')) {
      onClose()
      setIsClosing(false)
    }
  }

  const handleTouchStart = (e) => {
    setIsDragging(true)
    setStartPos(
      direction === 'left' || direction === 'right'
        ? e.touches[0].clientX
        : e.touches[0].clientY
    )
  }

  const handleTouchMove = (e) => {
    if (isDragging) {
      setCurrentPos(
        direction === 'left' || direction === 'right'
          ? e.touches[0].clientX
          : e.touches[0].clientY
      )
    }
  }

  const handleTouchEnd = () => {
    if (isDragging) {
      const threshold = 100
      const delta = currentPos - startPos

      if (Math.abs(delta) > threshold) {
        if (
          (direction === 'right' && delta > 0) ||
          (direction === 'left' && delta < 0) ||
          (direction === 'top' && delta < 0) ||
          (direction === 'bottom' && delta > 0)
        ) {
          handleClose()
        }
      }
      setIsDragging(false)
    }
  }

  const handleClose = () => {
    setIsClosing(true)
  }

  return (
    <>
      <div className="drawer-backdrop" onClick={handleClose} />
      <div
        className={`drawer-box drawer-box--${direction} ${
          isClosing ? 'closing' : ''
        }`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onAnimationEnd={handleAnimationEnd}
      >
        {showCloseButton && (
          <div className="drawer-box__close-button" onClick={handleClose}>
            <CloseIconColor />
          </div>
        )}
        {children}
      </div>
    </>
  )
}

export default Drawer
