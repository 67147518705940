import { createSlice } from '@reduxjs/toolkit'

// const portalActions = ['ENTER_UNIT', 'ENTER_LESSON', 'EXIT_LESSON', 'EXIT_UNIT']

const initialState = {
  isPhaserVisible: false,
  isGameUIVisible: false,
  userGuid: null,
  action: null,
  unitGuid: null,
  unitName: null,
  lessonGuid: null,
  unitExitTile: null,
  isPracticing: false,
  isInQuizz: false,
  areLessonsUpdatedAfterPracticing: true,
  isGameKeyboardBlocked: false,
  moveCameraToPlayer: false,
  moveCameraToObjectActive: false,
  moveCameraToObjectIndex: -1,
  firstBuildingImage: null,
  isGameFromPop: false,
  currentDeviceType: 'desktop'
}

export const MetaberrySlice = createSlice({
  name: 'Metaberry',
  initialState,
  reducers: {
    setIsPhaserVisible: (state, { payload }) => {
      state.isPhaserVisible = payload
    },

    setIsGameUIVisible: (state, { payload }) => {
      state.isGameUIVisible = payload
    },

    setUserGuid: (state, { payload }) => {
      state.userGuid = payload
    },

    enterUnit: (state, { payload }) => {
      state.action = 'ENTER_UNIT'
      state.unitGuid = payload.unitGuid
      state.unitName = payload.unitName
      state.lessonGuid = null
      state.unitExitTile = payload.unitExitTile
    },

    enterLesson: (state, { payload }) => {
      state.action = 'ENTER_LESSON'
      state.lessonGuid = payload.lessonGuid
      state.areLessonsUpdatedAfterPracticing =
        payload.areLessonsUpdatedAfterPracticing
    },

    exitLesson: (state) => {
      state.action = 'EXIT_LESSON'
      state.lessonGuid = null
    },

    exitUnit: (state) => {
      state.action = 'EXIT_UNIT'
      state.unitGuid = null
      state.unitName = null
      state.lessonGuid = null
    },

    setIsPracticing: (state, { payload }) => {
      state.isPracticing = payload
    },

    setIsInQuizz: (state, { payload }) => {
      state.isInQuizz = payload
    },

    setAreLessonsUpdatedAfterPracticing: (state, { payload }) => {
      state.areLessonsUpdatedAfterPracticing = payload
    },

    setIsGameKeyboardBlocked: (state, { payload }) => {
      state.isGameKeyboardBlocked = payload
    },

    setMoveCameraToPlayer: (state, { payload }) => {
      state.moveCameraToPlayer = payload
    },

    setMoveCameraToObject: (state, { payload }) => {
      if (payload.active !== undefined) {
        state.moveCameraToObjectActive = payload.active
      }

      if (payload.index !== undefined) {
        state.moveCameraToObjectIndex = payload.index
      }
    },

    setIsGameFromPop: (state, { payload }) => {
      state.isGameFromPop = payload
    },

    resetRespawn: (state) => {
      state.unitGuid = null
      state.lessonGuid = null
      state.unitExitTile = null
    },

    setFirstBuildingImage: (state, { payload }) => {
      state.firstBuildingImage = payload
    },

    setCurrentDeviceType: (state, { payload }) => {
      state.currentDeviceType = payload
    },

    resetMetaberry: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const {
  setIsPhaserVisible,
  setIsGameUIVisible,
  setIsGameFromPop,
  setUserGuid,
  enterUnit,
  enterLesson,
  exitUnit,
  exitLesson,
  setIsPracticing,
  setIsInQuizz,
  setAreLessonsUpdatedAfterPracticing,
  setIsGameKeyboardBlocked,
  setMoveCameraToPlayer,
  setMoveCameraToObject,
  resetRespawn,
  setFirstBuildingImage,
  setCurrentDeviceType,
  resetMetaberry
} = MetaberrySlice.actions

export default MetaberrySlice.reducer
