import axios from 'axios'
import { getToken, clearToken } from './token'
import createEndPoint from './createEndPoint'

const instance = axios.create({
  // La baseURL se establecerá dinámicamente en cada llamada
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        redirectToLogin()
        throw error
      }
      if (error.response.status === 405) throw redirectTo405IfPositivoOrLayers()
      if (error.response.status === 400) throw redirectTo405IfPositivoOrLayers()
    }
    return Promise.reject(error)
  }
)

export const fetchNoToken = (endpoint, postPayload) => {
  const options = {
    method: 'GET',
    baseURL: createEndPoint() // Usamos createEndPoint para establecer la baseURL
  }

  if (postPayload) {
    options.method = 'POST'
    options.data = postPayload
  }

  return instance(endpoint, options)
}

export const fetchWithToken = (
  endpoint,
  hasToUseMainToken,
  payload,
  method,
  customToken,
  additionalOptions = {}
) => {
  const _token = customToken || getToken(hasToUseMainToken)

  const options = {
    method: method || 'GET',
    baseURL: createEndPoint(),
    headers: {
      Authorization: _token,
      'Content-Type': 'application/json'
    },
    ...additionalOptions
  }

  if (payload) {
    options.data = payload
  }

  return instance(endpoint, options)
}

function redirectToLogin() {
  clearToken()
  window.location.href = `${window.location.protocol}//${window.location.host}/login`
}

function redirectTo405IfPositivoOrLayers() {
  if (
    window.location.host.indexOf('positivo-hml.blueberry.app.br') > -1 ||
    window.location.host.indexOf('positivo.blueberry.app.br') > -1
  ) {
    window.location.href = `${window.location.protocol}//${window.location.host}/error-405`
  }

  const urlParams = new URLSearchParams(window.location.search)
  const isLayers = urlParams.get('layers_session')
  if (isLayers) {
    window.location.href = `${window.location.protocol}//${window.location.host}/error-405-contact`
  }
}

const fetchWithRetry = async (options) => {
  const {
    endpoint,
    method = 'GET',
    data = null,
    token,
    maxRetries = 3,
    timeout = 15000,
    validateResponse = (response) => response.status === 'success'
  } = options

  let retries = 0

  const makeRequest = async () => {
    try {
      const response = await fetchWithToken(
        endpoint,
        false,
        data,
        method,
        token,
        { timeout }
      )
      if (!validateResponse(response)) {
        throw new Error('API response validation failed')
      }
      return response.data
    } catch (error) {
      if (error?.code === 'ECONNABORTED' && retries < maxRetries) {
        retries++
        console.warn(
          `Intento ${retries} de ${maxRetries} fallido. Reintentando...`
        )
        return makeRequest()
      }
      throw (
        error ||
        new Error('Error desde servidor, puede que 403 (Token expirado)')
      )
    }
  }

  try {
    return await makeRequest()
  } catch (error) {
    console.error('Error en fetchWithRetry:', error)
    if (error?.code === 'ECONNABORTED') {
      throw new Error(
        `ERR_RETRIES_TIMED_OUT - Error de tiempo de espera después de ${maxRetries} intentos`
      )
    } else if (error.response) {
      throw new Error(
        `ERR_RETRIES_TIMED_OUT - Error HTTP ${error.response.status}: ${
          error.response.data?.message || error.response.data
        }`
      )
    } else if (error.request) {
      throw new Error(
        'ERR_RETRIES_TIMED_OUT - No se recibió respuesta del servidor'
      )
    }
    throw new Error(
      `ERR_RETRIES_TIMED_OUT - Error inesperado: ${error.message}`
    )
  }
}

export const fetchGlobal = (endpointFull, postPayload) => {
  const options = {
    method: 'GET',
    baseURL: endpointFull // Usamos la URL completa como baseURL
  }

  if (postPayload) {
    options.method = 'POST'
    options.data = postPayload
  }

  // Cambia 'instance(endpoint, options)' a 'instance(endpointFull, options)'
  return instance(endpointFull, options) // Usamos endpointFull aquí
}

export default {
  fetchNoToken,
  fetchWithToken,
  fetchWithRetry,
  fetchGlobal
}
