import React, { useEffect, useRef } from 'react'
import MapBackImage from '../../../assets/img/metaberry_ui/backToCourseMap.svg'
import useSound from '../../views/MetaberryView/hooks/useSound'
import {
  getPanelRenderCount,
  updatePanelRenderCount
} from '../../../services/settingsService'
import './mapCourseBackButton.scss'
import { store } from '../../../store/configureStore'

const MapCourseBackButton = ({ handleClick }) => {
  const refContainer = useRef()
  const { renderSoundFXController, playSoundEffect } = useSound()

  const isReadyGamification = store.getState().configuration.isReadyGamification

  useEffect(() => {
    getPanelRenderCount() === 0 && playSoundEffect('portalInfo')
    updatePanelRenderCount(getPanelRenderCount() + 1)

    return () => {
      updatePanelRenderCount(0)
    }
  }, [])

  return (
    <div
      className={`map-course-back-button phaser-dom ${
        !isReadyGamification ? 'map-course-back-button-top' : ''
      }`}
      ref={refContainer}
      onClick={() => {
        playSoundEffect('uiButton')
        setTimeout(() => handleClick(), 70)
      }}
      draggable="false"
      data-testid="map_course_back_button"
    >
      {renderSoundFXController()}
      <img src={MapBackImage} alt="" draggable="false" />
    </div>
  )
}

export default MapCourseBackButton
