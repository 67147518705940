import React, { useState, useEffect } from 'react' // Agregar useEffect
import { Analysis } from '../../../services/analysisService'
import './InternetConnectionAlert.scss'
import translate from '../../../i18n/translate'
import CONNECTION_STATUS from '../../../config/constants/connectionTypes'

const InternetConnectionAlert = ({ connectionStatus, school_name }) => {
  const [isVisible, setIsVisible] = useState(true) // Estado para manejar la visibilidad

  useEffect(() => {
    // Restablecer la visibilidad cuando el estado de conexión cambie
    switch (connectionStatus) {
      case CONNECTION_STATUS.SLOW:
        setIsVisible(true)
        Analysis.sendSegmentTrackEvent(
          Analysis.EVENT.CONNECTION_NOTIFICATION_LOW,
          {
            username: Analysis.getUsername(),
            school_name: school_name
          }
        )
        break
      case CONNECTION_STATUS.OFFLINE:
        setIsVisible(true)
        Analysis.sendSegmentTrackEvent(
          Analysis.EVENT.CONNECTION_NOTIFICATION_LOST,
          {
            username: Analysis.getUsername(),
            school_name: school_name
          }
        )
        break

      default:
        break
    }
  }, [connectionStatus]) // Dependencia de connectionStatus

  if (connectionStatus === CONNECTION_STATUS.ONLINE || !isVisible) return null // Verificar visibilidad

  return (
    <div className={`internet-connection-alert ${connectionStatus}`}>
      <div className="internet-connection-alert-icon-container">
        {/* Se añaden los svg directamente ya que al no tener conexión o ser lenta tarda en cagar si tenemos que hacer una petición al icono */}
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="36" height="36" rx="7.2" fill="#FF3D30" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.6404 17.9994C26.6404 22.7711 22.7721 26.6394 18.0004 26.6394C13.2286 26.6394 9.36035 22.7711 9.36035 17.9994C9.36035 13.2276 13.2286 9.35938 18.0004 9.35938C22.7721 9.35938 26.6404 13.2276 26.6404 17.9994ZM19.0804 22.3194C19.0804 22.9158 18.5968 23.3994 18.0004 23.3994C17.4039 23.3994 16.9204 22.9158 16.9204 22.3194C16.9204 21.7229 17.4039 21.2394 18.0004 21.2394C18.5968 21.2394 19.0804 21.7229 19.0804 22.3194ZM18.0004 12.5994C17.4039 12.5994 16.9204 13.0829 16.9204 13.6794V17.9994C16.9204 18.5958 17.4039 19.0794 18.0004 19.0794C18.5968 19.0794 19.0804 18.5958 19.0804 17.9994V13.6794C19.0804 13.0829 18.5968 12.5994 18.0004 12.5994Z"
            fill="white"
          />
        </svg>
      </div>

      {connectionStatus === 'slow'
        ? translate('internet_connection_slow')
        : translate('internet_connection_ko')}

      {connectionStatus === 'slow' && (
        <div
          className="internet-connection-alert-close-container"
          onClick={() => setIsVisible(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            className="internet-connection-alert-close-container-svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.43451 0.433533C0.746929 0.121114 1.25346 0.121114 1.56588 0.433533L5.0002 3.86785L8.43451 0.433533C8.74693 0.121114 9.25346 0.121114 9.56588 0.433533C9.8783 0.745953 9.8783 1.25248 9.56588 1.5649L6.13157 4.99922L9.56588 8.43353C9.8783 8.74595 9.8783 9.25249 9.56588 9.5649C9.25346 9.87732 8.74693 9.87732 8.43451 9.5649L5.0002 6.13059L1.56588 9.5649C1.25346 9.87732 0.746929 9.87732 0.43451 9.5649C0.12209 9.25249 0.12209 8.74595 0.43451 8.43353L3.86882 4.99922L0.43451 1.5649C0.12209 1.25248 0.12209 0.745953 0.43451 0.433533Z"
              fill="#4D586C"
            />
          </svg>
        </div>
      )}
    </div>
  )
}

export default InternetConnectionAlert
