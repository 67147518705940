import Phaser from 'phaser'
import BootScene from './scenes/BootScene'
import MainScene from './scenes/MainScene'

const GAME_CONFIGURATION = {
  width: '100%',
  height: '100%',
  backgroundColor: '#000',

  type: process.env.CI ? Phaser.CANVAS : Phaser.AUTO, // Usa CANVAS en CI

  scale: {
    parent: 'phaser-game',
    mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH
  },

  dom: { createContainer: true },

  disableContextMenu: true,

  render: {
    antialiasGL: false, // prevent tile bleeding
    antialias: true,

    pixelArt: false // If true, no blur on pixels + zoom
  },

  physics: {
    default: 'matter',
    matter: {
      gravity: { y: 0 },
      debug: false
    }
  },
  scene: [BootScene, MainScene]
}

const BACKGROUND_MUSIC_VOLUME = 0.3

export { GAME_CONFIGURATION, BACKGROUND_MUSIC_VOLUME }
