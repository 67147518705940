import React from 'react'
import Coins01Img from '../../../../assets/coins-01.svg'
import Coins02Img from '../../../../assets/coins-02.svg'
import Coins03Img from '../../../../assets/coins-03.svg'
import Coins04Img from '../../../../assets/coins-04.svg'
import Coins05Img from '../../../../assets/coins-05.svg'
import RibbonOnImg from '../../../../assets/ribbon_on.svg'
import RibbonOffImg from '../../../../assets/ribbon_off.svg'
import CheckImg from '../../../../assets/streak_check.svg'
import translate from '../../../../../../../i18n/translate'
import './StreakCard.scss'

// Variants: past, today, tomorrow, future

const StreakCard = ({ streakDay, step, streakInfo }) => {
  let coinsObj
  let variant
  let ribbonImage

  // step                  1   2  3  4  5
  // step               1  2   3  4  5
  // step            1  2  3   4  5
  // step         1  2  3  4   5
  // step      1  2  3  4  5
  // streakDay 0  1  2  3  4   5  6  7  8  9  10
  //           10 20 30 40 50  50 50 50 50 50 50

  const REACHEABLE_MAX_STEP = 4

  const customizeCard = () => {
    const auxStreakDay =
      streakDay < REACHEABLE_MAX_STEP
        ? step
        : Math.min(streakDay, 8) - (REACHEABLE_MAX_STEP - step)

    switch (auxStreakDay) {
      case 1:
        coinsObj = [streakInfo ? streakInfo[0]?.rewardAmount : 10, Coins01Img]
        break
      case 2:
        coinsObj = [
          streakInfo ? streakInfo[0]?.rewardAmount + 10 : 20,
          Coins02Img
        ]
        break
      case 3:
        coinsObj = [
          streakInfo ? streakInfo[1]?.rewardAmount + 10 : 30,
          Coins03Img
        ]
        break
      case 4:
        coinsObj = [
          streakInfo ? streakInfo[2]?.rewardAmount + 10 : 40,
          Coins04Img
        ]
        break
      case 5:
      default:
        coinsObj = [
          streakInfo ? streakInfo[3]?.rewardAmount + 10 : 50,
          Coins05Img
        ]
        break
    }

    ribbonImage = getRibbonImage(auxStreakDay)
    variant = getVariant()
  }

  const getRibbonImage = (auxStreakDay) => {
    return step > auxStreakDay ? RibbonOffImg : RibbonOnImg
  }

  const getVariant = () => {
    const auxStreakDay = streakDay

    if (streakDay < REACHEABLE_MAX_STEP) {
      if (step < auxStreakDay) return 'past'
      if (step === auxStreakDay) return 'today'
      if (step === auxStreakDay + 1) return 'tomorrow'
      if (step > auxStreakDay) return 'future'
    } else {
      if (step === REACHEABLE_MAX_STEP) return 'today'
      if (step >= REACHEABLE_MAX_STEP) return 'tomorrow'
      return 'past'
    }
  }

  customizeCard()

  return (
    <div className={`streak-card streak-card--${variant}`}>
      {variant === 'past' && (
        <div className="streak-card__check">
          <img src={CheckImg} alt="" />
        </div>
      )}
      {variant === 'tomorrow' && (
        <div className="streak-card__top-text">
          {translate('reward_daily_goal__tomorrow')}
        </div>
      )}
      <div className="streak-card__image">
        <img src={coinsObj[1]} alt="" />
      </div>
      <div className="streak-card__ribbon-wrapper">
        <div className="streak-card__ribbon">
          <img src={ribbonImage} alt="" />
        </div>
        <div className="streak-card__number">+{coinsObj[0]}</div>
      </div>
    </div>
  )
}

export default StreakCard
