import React from 'react'
import { ReactComponent as SvgOk } from '../../../assets/icons/ok.svg'
import { ReactComponent as SvgNotOk } from '../../../assets/icons/notOk.svg'
import './notificationDrawer.scss'
import Drawer from '../../atoms/Drawer/Drawer'

const NotificationDrawer = ({ data, onClose }) => {
  return (
    <div className="notification-drawer">
      <Drawer isOpen={true} direction="bottom" onClose={onClose}>
        {data.type === 'success' ? (
          <SvgOk className="toast-blueberry_icon" />
        ) : (
          <SvgNotOk className="toast-blueberry_icon" />
        )}
        <div className="toast-blueberry_title">{data.title}</div>
        <div className="toast-blueberry_message">{data.message}</div>
      </Drawer>
    </div>
  )
}

export default NotificationDrawer
