import React, { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { ActivityProvider } from '../../context/ActivityContext/ActivityContext'
import { useActivityContext } from '../../context/ActivityContext/useActivityContext'
import i18n from '../../locales/i18config'
import { ExerciseAudioManager } from '../../utils/exerciseAudioManager'
import ActivityView from '../../views/ActivityView/ActivityView'
import './activityBlueberryComponent.scss'
import { CROSS_SYMBOL } from '@oneclick/react-lemonade-editor'
// Componente que proporciona el contexto
function ActivityBlueberryComponent({
  activityType,
  activity,
  onActivityEvent = () => {},
  uiLanguage,
  isDemoMode,
  activityInformation,
  disabledQuestion,
  showSolutionQuestion,
  isHideFooter = false,
  studentResponse,
  showAllFeedback = true,
  finishedTimeQuestion,
  hasCheckExerciseAPiResponded = true,
  requiresAudioAssistance = false,
  exerciseLanguage,
  hasPreviowsPopups,
  getStimulusAudio,
  getTextAudio,
  numberQuestion,
  showSolutionQuestionQuizPreview,
  mathRenderEngine = 'mathjax',
  multiplicationSymbol = CROSS_SYMBOL,
  pathAssetsSchool
}) {
  useEffect(() => {
    i18n.changeLanguage(uiLanguage || 'en')
    ExerciseAudioManager.initialize(exerciseLanguage)
  }, [])

  return (
    <ActivityProvider
      activityType={activityType}
      activity={activity}
      isDemoMode={isDemoMode}
      disabledQuestion={disabledQuestion}
    >
      <ActivityBlueberryComponentWithProvider
        activity={activity}
        isDemoMode={isDemoMode}
        activityInformation={activityInformation}
        showSolutionQuestion={showSolutionQuestion}
        isHideFooter={isHideFooter}
        onActivityEvent={onActivityEvent}
        studentResponse={studentResponse}
        showAllFeedback={showAllFeedback}
        finishedTimeQuestion={finishedTimeQuestion}
        hasCheckExerciseAPiResponded={hasCheckExerciseAPiResponded}
        requiresAudioAssistance={requiresAudioAssistance}
        hasPreviowsPopups={hasPreviowsPopups}
        getStimulusAudio={getStimulusAudio}
        getTextAudio={getTextAudio}
        numberQuestion={numberQuestion}
        showSolutionQuestionQuizPreview={showSolutionQuestionQuizPreview}
        mathRenderEngine={mathRenderEngine}
        multiplicationSymbol={multiplicationSymbol}
        exerciseLanguage={exerciseLanguage}
        pathAssetsSchool={pathAssetsSchool}
      />
    </ActivityProvider>
  )
}

function ActivityBlueberryComponentWithProvider({
  activity,
  isDemoMode,
  activityInformation,
  showSolutionQuestion,
  isHideFooter,
  onActivityEvent,
  studentResponse,
  showAllFeedback,
  finishedTimeQuestion,
  hasCheckExerciseAPiResponded,
  requiresAudioAssistance,
  hasPreviowsPopups,
  getStimulusAudio,
  getTextAudio,
  numberQuestion,
  showSolutionQuestionQuizPreview,
  mathRenderEngine,
  multiplicationSymbol,
  exerciseLanguage,
  pathAssetsSchool
}) {
  const { setActivity, setIsDemoMode } = useActivityContext()

  useEffect(() => {
    setActivity(activity)
  }, [activity])

  useEffect(() => {
    setIsDemoMode(isDemoMode)
  }, [isDemoMode])

  return (
    <I18nextProvider i18n={i18n}>
      <div className="activity-blueberry-component">
        <ActivityView
          showSolutionQuestion={showSolutionQuestion}
          isHideFooter={isHideFooter}
          onActivityEvent={onActivityEvent}
          activityInformation={activityInformation}
          studentResponse={studentResponse}
          showAllFeedback={showAllFeedback}
          finishedTimeQuestion={finishedTimeQuestion}
          hasCheckExerciseAPiResponded={hasCheckExerciseAPiResponded}
          requiresAudioAssistance={requiresAudioAssistance}
          hasPreviowsPopups={hasPreviowsPopups}
          getStimulusAudio={getStimulusAudio}
          getTextAudio={getTextAudio}
          numberQuestion={numberQuestion}
          showSolutionQuestionQuizPreview={showSolutionQuestionQuizPreview}
          mathRenderEngine={mathRenderEngine}
          multiplicationSymbol={multiplicationSymbol}
          exerciseLanguage={exerciseLanguage}
          pathAssetsSchool={pathAssetsSchool}
        />
      </div>
    </I18nextProvider>
  )
}

export default ActivityBlueberryComponent
