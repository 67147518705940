import React, { useEffect, useState, useRef } from 'react'
import { ReactComponent as LockIcon } from '../../../assets/img/metaberry_ui/lock.svg'
import translate from '../../../i18n/translate'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { ReactComponent as SvgGoldGear } from '../../../assets/img/metaberry_ui/gold-gear.svg'
import TitleContainer from '../../atoms/TitleContainer/TitleContainer'
// import { Analysis } from '../../../services/analysisService'
// import { getCourseGuid } from '../../../assets/data/api'
// import { store } from '../../../store/configureStore'

const MetaLessonPanel = ({
  isAvailable,
  unitGuid,
  lessonGuid,
  lessonName,
  lessonNumber,
  level,
  previousLessonGuid,
  previousLessonName,
  previousLessonImage,
  pieces,
  handleEnterButton,
  status,
  currentTileSize,
  playerScreenPosition,
  batteryScreenPosition,
  isFixed
}) => {
  const refContainer = useRef()

  const [BackgroundCircleImage] = useState(
    `${process.env.PUBLIC_URL}/assets/graphics/images/background-gradient-circle.svg`
  )

  const [panelStyle, setPanelStyle] = useState({
    top: 0,
    left: 0,
    visibility: 'hidden',
    transition: 'none'
  })
  const [piecesHave, setPiecesHave] = useState(0)
  const [piecesTotal, setPiecesTotal] = useState(0)

  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    if (pieces?.have) {
      setPiecesHave(pieces.have.reduce((total, current) => total + current, 0))
    }
    if (pieces?.pieces) {
      setPiecesTotal(
        pieces.pieces.reduce((total, current) => total + current, 0)
      )
    }

    playSoundEffect('portalInfo')

    if (!isFixed) {
      // Calcular posición del panel en base al personaje y la batería activa
      const gameContainer = document.querySelector('#phaser-game')
      const thisPanel = refContainer.current

      // Asegurar que se puede modificar
      let panelX = Math.round(window.outerWidth / 2)
      let panelY = Math.round(window.outerHeight / 2)
      if (
        gameContainer &&
        thisPanel &&
        playerScreenPosition?.x &&
        batteryScreenPosition?.x &&
        currentTileSize
      ) {
        const gameRect = gameContainer.getBoundingClientRect()
        const panelWidth = thisPanel.offsetWidth
        const panelHeight = thisPanel.offsetHeight

        panelX = getPanelX(
          gameRect,
          playerScreenPosition,
          batteryScreenPosition,
          panelWidth,
          currentTileSize
        )

        panelY = getPanelY(
          gameRect,
          playerScreenPosition,
          batteryScreenPosition,
          panelHeight,
          currentTileSize
        )
      }

      setPanelStyle({
        top: panelY,
        left: panelX,
        visibility: 'visible',
        transition: 'none'
      })

      document.addEventListener('player-moved', updatePosition)
    }

    return () => {
      if (!isFixed) document.removeEventListener('player-moved', updatePosition)
    }
  }, [])

  function isPlayerInPanelArea_Horizontal(
    playerLeftX,
    playerRightX,
    areaLeft,
    areaRight
  ) {
    const isOverlaped =
      areaLeft - 2 < playerRightX && playerLeftX < areaRight + 2

    return isOverlaped
  }

  function getPanelX(
    gameRect,
    playerPosition,
    batteryPosition,
    panelWidth,
    tileSize
  ) {
    // Posibles posiciones
    // 1 Derecha                    2 Derecha más 1 tile
    // 3 Izquierda                  4 Izquierda menos 1 tile
    // 5 forzado exterior derecha   6 Menor de los males
    const halfTile = Math.round(tileSize * 0.5)
    const panelXBaseRight = batteryPosition.x + tileSize + halfTile
    const panelXBaseLeft = batteryPosition.x - panelWidth - halfTile
    const playerLeftX = playerPosition.x - halfTile
    const playerRightX = playerPosition.x + halfTile

    // Se comprueba si cabe en el lado derecho
    let panelLeft = panelXBaseRight
    let panelRight = panelLeft + panelWidth

    if (
      panelRight + tileSize < gameRect.width &&
      !isPlayerInPanelArea_Horizontal(
        playerLeftX,
        playerRightX,
        panelLeft,
        panelRight
      )
    ) {
      return panelLeft
    } else {
      // Se comprueba si cabe en el lado derecho + 1 tile de espacio para el personaje
      panelLeft += tileSize
      panelRight += tileSize

      if (
        panelRight + tileSize < gameRect.width &&
        !isPlayerInPanelArea_Horizontal(
          playerLeftX,
          playerRightX,
          panelLeft,
          panelRight
        )
      ) {
        return panelLeft
      } else {
        // Se comprueba si cabe en el lado izquierdo
        panelLeft = panelXBaseLeft
        panelRight = panelLeft + panelWidth

        if (
          panelLeft > tileSize &&
          !isPlayerInPanelArea_Horizontal(
            playerLeftX,
            playerRightX,
            panelLeft,
            panelRight
          )
        ) {
          return panelLeft
        } else {
          // Se comprueba si cabe en el lado izquierdo - 1 tile de espacio para el personaje
          panelLeft -= tileSize
          panelRight -= tileSize

          if (
            panelLeft > tileSize &&
            !isPlayerInPanelArea_Horizontal(
              playerLeftX,
              playerRightX,
              panelLeft,
              panelRight
            )
          ) {
            return panelLeft
          } else {
            // Si es obligatorio se escoge la mejor de las peores opciones
            panelLeft = panelXBaseRight
            panelRight = panelLeft + panelWidth

            if (panelRight + tileSize < gameRect.width) {
              return panelLeft
            } else {
              return panelXBaseLeft + halfTile
            }
          }
        }
      }
    }
  }

  function getPanelY(
    gameRect,
    playerPosition,
    batteryPosition,
    panelHeight,
    tileSize
  ) {
    const halfTile = Math.round(tileSize * 0.5)

    // Se comprueba si se sale por arriba (con 1 tile de margen), sino se baja
    let panelY = batteryPosition.y - halfTile
    if (panelY < tileSize) panelY = tileSize

    // Se comprueba si se sale por debajo, sino se sube
    const overflowY = gameRect.height - (panelY + panelHeight + tileSize)
    if (overflowY < 0) {
      panelY += overflowY
    }

    return panelY
  }

  // eslint-disable-next-line no-unused-vars
  function updatePosition(event) {
    if (!isFixed) {
      const playerPosition = event.detail.playerPosition
      const batteryPosition = event.detail.batteryPosition
      const tileSize = event.detail.currentTileSize
      // Calcular posición del panel en base al personaje y la batería activa
      const gameContainer = document.querySelector('#phaser-game')
      const thisPanel = refContainer.current

      if (
        gameContainer &&
        thisPanel &&
        playerPosition?.x &&
        batteryPosition?.x &&
        tileSize
      ) {
        const gameRect = gameContainer.getBoundingClientRect()
        const panelWidth = thisPanel.offsetWidth
        const panelHeight = thisPanel.offsetHeight

        const panelX = getPanelX(
          gameRect,
          playerPosition,
          batteryPosition,
          panelWidth,
          tileSize
        )
        const panelY = getPanelY(
          gameRect,
          playerPosition,
          batteryPosition,
          panelHeight,
          tileSize
        )

        if (panelStyle.left !== panelX || panelStyle.top !== panelY) {
          setPanelStyle({
            top: panelY,
            left: panelX,
            visibility: 'visible',
            transition: 'top 500ms, left 500ms'
          })
        }
      }
    }
  }

  function getButtonText(status) {
    const hasAllPieces =
      JSON.stringify(pieces.have) === JSON.stringify(pieces.pieces)

    const texts = {
      install: 'lesson_install_btn',
      empty: 'lesson_recharge_btn',
      init: 'lesson_recharge_btn',
      charging: 'lesson_recharge_btn',
      complete: hasAllPieces ? 'lesson_practice_btn' : 'lesson_get_pieces_btn',
      start: hasAllPieces ? 'lesson_practice_btn' : 'lesson_get_pieces_btn',
      broked_start: 'lesson_repair_btn'
    }

    return translate(texts[status])
  }

  /*
  async function handleWhereInner() {
  const courseGuid = await getCourseGuid()
  const whereUnitEventObject = {
    program_id: store.getState().practice.programId,
    course_id: courseGuid,
    unit_id: unitGuid,
    lesson_id: lessonGuid,
    lesson_name: lessonName
  }

  if (previousLessonGuid) {
    whereUnitEventObject.where_lesson_id = previousLessonGuid
    whereUnitEventObject.where_lesson_name = previousLessonName
  }

  Analysis.sendSegmentPageEvent(
    Analysis.SEGMENT_PAGE_CATEGORIES.Game,
    Analysis.SEGMENT_EVENTS['Lesson Where Button Clicked'],
    whereUnitEventObject
  )

  handleWhereButton()
  }
  */

  return (
    <div
      className={'meta-lesson-panel phaser-dom'}
      style={panelStyle}
      ref={refContainer}
      data-fixed={isFixed}
    >
      <div
        className="meta-lesson-panel_decoration"
        style={{ backgroundImage: `url(${BackgroundCircleImage})` }}
      ></div>
      {renderSoundFXController()}
      {!isAvailable ? (
        <div className="meta-lesson-panel__blocked">
          <div className="meta-lesson-panel__blocked-icon">
            <LockIcon />
          </div>
          <div className="meta-lesson-panel__blocked-title">
            {translate('lesson_blocked')}
          </div>
          <div className="meta-lesson-panel__blocked-info">
            {translate('lesson_blocked_info')}
            <span className="meta-lesson-panel__previous-name">
              {previousLessonName}
            </span>
          </div>
          {previousLessonImage && (
            <div className="meta-lesson-panel__previous-image">
              <img src={previousLessonImage} />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="meta-lesson-panel__lesson-number">
            {`${translate('lesson_battery_number')} ${lessonNumber}`}
          </div>
          <TitleContainer
            className="meta-lesson-panel__lesson-title"
            titleText={lessonName}
          ></TitleContainer>

          <div className="meta-lesson-panel__lesson-pieces">
            <div className="meta-lesson-panel__lesson-pieces_gear">
              <SvgGoldGear />
            </div>
            <div className="meta-lesson-panel__lesson-pieces_bar">
              <div
                className="meta-lesson-panel__lesson-pieces_bar_foregrond"
                style={{ width: (piecesHave * 100) / piecesTotal + '%' }}
              ></div>
            </div>
            <div className="meta-lesson-panel__lesson-pieces_number">
              <div>{piecesHave}</div>
              <div>{'/'}</div>
              <div>{piecesTotal}</div>
            </div>
          </div>

          <div
            className="meta-lesson-panel__button"
            onClick={() => {
              playSoundEffect('uiButton')
              // setTimeout(() => handleWhereInner(), 70)
              handleEnterButton()
            }}
            data-testid="meta_lesson_panel_button"
          >
            {getButtonText(status)}
          </div>
        </>
      )}
    </div>
  )
}

export default MetaLessonPanel
