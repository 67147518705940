import React, { useRef, useState } from 'react'
import { LemonadeRenderContext } from '@oneclick/react-lemonade-editor'
import './feedbackPanelQuiz.scss'

const FeedbackPanelQuiz = ({
  title,
  subtitle,
  content,
  iconType,
  isResponseSend = false,
  pathAssetsSchool
}) => {
  // const PANEL_WITHOUT_SHOW_MORE_MAX_HEIGHT = document.body.clientHeight / 2
  // content = '<p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p><p>aaaaa bbbbbb cccccc ddddddd eeeeee ffffff zzzz</p>'
  const feedbackContent = useRef()

  const panelRef = useRef()

  const [svgBlueberryCorrect_0] = useState(
    `${pathAssetsSchool}/feedback_correct_0.svg`
  )
  const [svgBlueberryCorrect_2] = useState(
    `${pathAssetsSchool}/feedback_correct_2.svg`
  )
  const [svgBlueberryIncorrect_2] = useState(
    `${pathAssetsSchool}/feedback_incorrect_2.svg`
  )

  // Establecer tipo e icono para el panel de feedback
  let feedbackType = 0
  let FeedbackIcon = null

  switch (iconType) {
    case 'correct_0': {
      feedbackType = 0
      FeedbackIcon = svgBlueberryCorrect_2
      break
    }

    case 'correct_1': {
      feedbackType = 0
      FeedbackIcon = svgBlueberryCorrect_2
      break
    }

    case 'correct_2': {
      feedbackType = 0
      FeedbackIcon = svgBlueberryCorrect_2
      break
    }

    case 'incorrect_0': {
      feedbackType = 1
      FeedbackIcon = svgBlueberryIncorrect_2
      break
    }

    case 'incorrect_1': {
      feedbackType = 1
      FeedbackIcon = svgBlueberryIncorrect_2
      break
    }

    case 'incorrect_2': {
      feedbackType = 1
      FeedbackIcon = svgBlueberryIncorrect_2
      break
    }

    case 'quiz_send_answer': {
      feedbackType = 3
      FeedbackIcon = svgBlueberryCorrect_0
      break
    }

    case 'hint':
    default: {
      feedbackType = 2
      FeedbackIcon = svgBlueberryCorrect_0
      break
    }
  }

  return (
    <div className="feedback-panel-quiz">
      <div
        className={'feedback-panel__box__quiz type-' + feedbackType}
        ref={panelRef}
      >
        <div className={'feedback-panel__blueberry'}>
          {FeedbackIcon && <img src={FeedbackIcon} alt="" />}
        </div>

        {title && (
          <div className="feedback-panel__title-box">
            <div
              className="feedback-panel__title-text"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          </div>
        )}

        {subtitle && (
          <div className="feedback-panel__subtitle-box">
            <div
              className="feedback-panel__subtitle-text"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            ></div>
          </div>
        )}

        {content && !isResponseSend && (
          <div className="feedback-panel__content">
            <LemonadeRenderContext>
              <div
                ref={feedbackContent}
                className="lemonade-feedback-message"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
            </LemonadeRenderContext>
          </div>
        )}
      </div>
    </div>
  )
}

export default FeedbackPanelQuiz
