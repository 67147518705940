import { useState, useEffect, useRef } from 'react'
import useSocket from '../hooks/useSocket'
import {
  getContent,
  getQuizCourseUsers,
  getQuizCourseUsersTeachers,
  sendAnswerQuiz
} from '../../../../assets/data/api'
import {
  getStoredSettings,
  getUserAvatar
} from '../../../../services/settingsService'
import {
  getAloneUserData,
  getFamilyUserData
} from '../../../../services/userTypeService'
import { getWhoAmI } from '../../../../services/courseInfoService'

export default function useQuiz(
  getToken,
  Analysis,
  handleExitTeacherPractice,
  playSoundEffect
) {
  const statusMapPractice = {
    IN_QUESTION: 'question',
    IN_RANKING: 'ranking',
    IN_PODIUM: 'podium'
  }

  const [dataPlayersTeacherPracticeStart, setDataPlayersTeacherPracticeStart] =
    useState([])
  const [dataPlayersTeacherPractice, setDataPlayersTeacherPractice] = useState(
    []
  )

  const [isCreated, setIsCreated] = useState(false)
  const [insideLobby, setInsideLobby] = useState(false)
  const [insidePractice, setInsidePractice] = useState(false)
  const [totalPlayers, setTotalPlayers] = useState(0)
  const [conectedPlayers, setConectedPlayers] = useState(0)
  const [quizGuid, setQuizGuid] = useState('')
  const [gameGuid, setGameGuid] = useState('')
  const [contentQuiz, setContentQuiz] = useState({})
  const [statusPractice, setStatusPractice] = useState({ phase: '' })
  const [numberCorrectQuestion, setNumberCorrectQuestion] = useState(0)

  // Establecer el estado inicial del contador
  const [countDownTeacherPractice, setCountDownTeacherPractice] = useState(null)
  const [wasMapCreatedStated, setWasMapCreatedStated] = useState(false)

  const [keyCountDown, setKeyCountDown] = useState(Math.random())

  const [timerQuestion, setTimerQuestion] = useState(null)

  const [showModalExitPractice, setShowModalExitPractice] = useState(false)

  const [isTeacherDisconnected, setIsTeacherDisconnected] = useState(false)

  const [userAvatarImg, setUserAvatarImg] = useState(
    getUserAvatar().navigation_icon_image
  )

  const [isDeletePractice, setIsDeletePractice] = useState(false)
  const timer = useRef(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [isPlayerJoinGameStarted, setIsPlayerJoinGameStarted] = useState(false)

  const [userIsDemo, setUserIsDemo] = useState(false)

  const [forcedQuizExit, setForcedQuizExit] = useState(false)

  const [finishedTimeQuestion, setFinishedTimeQuestion] = useState(false)

  const goFullScreen = () => {
    setIsFullscreen(true)
    const docElm = document.documentElement
    if (docElm.webkitRequestFullScreen) {
      // Chrome, Safari (and Opera)
      docElm.webkitRequestFullScreen()
    } else if (docElm.mozRequestFullScreen) {
      // Firefox
      docElm.mozRequestFullScreen()
    } else if (docElm.msRequestFullscreen) {
      // IE/Edge
      docElm.msRequestFullscreen()
    }
  }

  const {
    messageSocket,
    playersSocketTeacherPractice,
    connectSocket,
    joinGame,
    numberQuestion,
    setNumberQuestion,
    secondsToAnswer,
    syncTimestamp,
    showSolution,
    setShowSolution,
    sendAnswer,
    usersDisconnected
  } = useSocket(
    setInsideLobby,
    setStatusPractice,
    statusMapPractice,
    setIsTeacherDisconnected,
    setIsDeletePractice,
    setInsidePractice,
    setIsPlayerJoinGameStarted,
    Analysis
  )

  useEffect(() => {
    if (statusPractice.phase === statusMapPractice.IN_QUESTION) {
      if (timerQuestion === null) {
        // La primera pregunta tiene una cuenta atrás adicional
        // const auxSecondsToAnswer = numberQuestion === 0 ? secondsToAnswer + 4 : secondsToAnswer
        const auxSecondsToAnswer = secondsToAnswer
        setTimerQuestion(auxSecondsToAnswer)
      }
    } else {
      setTimerQuestion(null)
    }

    if (isPlayerJoinGameStarted) {
      setIsPlayerJoinGameStarted(false)
    }
  }, [statusPractice])

  useEffect(() => {
    if (showSolution) {
      setTimerQuestion(null)
    }
  }, [showSolution])

  useEffect(() => {
    if (isTeacherDisconnected) {
      if (isCreated) {
        setShowModalExitPractice(true)
        Analysis.sendSegmentTrackEvent(
          Analysis.EVENT.PRACTICE_ABORTED_POP_UP_VIEWED,
          {
            practice_id: quizGuid
          }
        )
      }
    }
  }, [isTeacherDisconnected])

  const secondsRef = useRef(timerQuestion)
  useEffect(() => {
    secondsRef.current = timerQuestion

    if (timerQuestion > 0) {
      const timerQuestionInterval = setInterval(() => {
        // La primera pregunta tiene una cuenta atras adicional de 4 segundos
        const questionSecondsToAnswer =
          secondsToAnswer + (numberQuestion === 0 ? 4 : 0)
        const endTimestap = syncTimestamp + questionSecondsToAnswer * 1000
        let distance = endTimestap - new Date().getTime()
        if (distance <= 0) {
          distance = 0
          clearInterval(timerQuestionInterval)
        }

        const secondsLeft = Math.ceil(distance / 1000)
        if (secondsLeft !== secondsRef.current) {
          setTimerQuestion(secondsLeft)
        }
      }, 200)

      return () => {
        clearInterval(timerQuestionInterval)
      }
    }
  }, [timerQuestion])

  useEffect(() => {
    if (showModalExitPractice) {
      clearInterval(timer.current) // Limpiamos el intervalo si showModalExitPractice es true
    }
  }, [showModalExitPractice])

  useEffect(() => {
    if (numberQuestion === 0) {
      setCountDownTeacherPractice(3)
      onStartCountDown()
    } else {
      if (numberQuestion != null) {
        setInsidePractice(true)
        setTimeout(() => {
          setInsideLobby(false)
        }, 300)
        setStatusPractice({ phase: statusMapPractice.IN_QUESTION })
      }
    }
  }, [numberQuestion])

  useEffect(() => {
    if (wasMapCreatedStated) {
      connectSocket(getToken()) // Se conecta al socket para estar escuchando notificaciones para las teacher practice
    }
  }, [wasMapCreatedStated])

  const gameStartedHandle = () => {
    setWasMapCreatedStated(true)
  }

  useEffect(() => {
    // Crear una copia de dataPlayersTeacherPractice
    const newDataPlayersTeacherPractice = [...dataPlayersTeacherPractice]

    // Recorrer cada playerSocket
    newDataPlayersTeacherPractice.forEach((playerSocket) => {
      // Encontrar el índice del jugador en el array newDataPlayersTeacherPractice por guid
      const playerIndex = newDataPlayersTeacherPractice.findIndex(
        (playerData) => usersDisconnected.includes(playerData.person_guid)
      )

      // Si el jugador existe, agregar la propiedad is_online en un nuevo objeto y reemplazar el antiguo
      if (playerIndex !== -1) {
        newDataPlayersTeacherPractice[playerIndex] = {
          ...newDataPlayersTeacherPractice[playerIndex],
          is_online: false
        }
      }
    })

    // Utilizar setDataPlayersTeacherPractice para actualizar el estado con el nuevo array modificado
    setDataPlayersTeacherPractice(newDataPlayersTeacherPractice)

    // Filtrar los jugadores que tienen is_online true
    const onlinePlayers = newDataPlayersTeacherPractice.filter(
      (player) => player.is_online === true
    )

    setConectedPlayers(onlinePlayers.length)
  }, [usersDisconnected])

  useEffect(() => {
    // Crear una copia de dataPlayersTeacherPractice
    const newDataPlayersTeacherPractice = [...dataPlayersTeacherPracticeStart]

    // Recorrer cada playerSocket
    playersSocketTeacherPractice.forEach((playerSocket) => {
      // Encontrar el índice del jugador en el array newDataPlayersTeacherPractice por guid
      const playerIndex = newDataPlayersTeacherPractice.findIndex(
        (playerData) => playerData.person_guid === playerSocket.guid
      )

      // Si el jugador existe, agregar la propiedad is_online en un nuevo objeto y reemplazar el antiguo
      if (playerIndex !== -1) {
        newDataPlayersTeacherPractice[playerIndex] = {
          ...newDataPlayersTeacherPractice[playerIndex],
          is_online: playerSocket.is_online
        }
      }
    })

    // Utilizar setDataPlayersTeacherPractice para actualizar el estado con el nuevo array modificado
    setDataPlayersTeacherPractice(newDataPlayersTeacherPractice)

    // Filtrar los jugadores que tienen is_online true
    const onlinePlayers = newDataPlayersTeacherPractice.filter(
      (player) => player.is_online === true
    )
    setTotalPlayers(newDataPlayersTeacherPractice.length)
    setConectedPlayers(onlinePlayers.length)
  }, [playersSocketTeacherPractice])

  const startQuiz = async (objSocket) => {
    // Con el quizGuid hacemos un get para obtener la practica
    if (objSocket.quizGuid) {
      const dataContentQuizz = await getContent(objSocket.quizGuid)

      if (dataContentQuizz) {
        setContentQuiz(dataContentQuizz)

        setUserAvatarImg(getUserAvatar().navigation_icon_image)
        setIsCreated(true)

        // Cuando tengamos misiones se tendrán que enviar estos eventos
        Analysis.sendSegmentTrackEvent(Analysis.EVENT.MISSION_POP_UP_VIEWED, {
          practice_id: objSocket.quizGuid,
          is_practice: true
        })
      }
    }
  }

  const [newQuizFromPodium, setNewQuizFromPodium] = useState(null)
  useEffect(() => {
    if (newQuizFromPodium) {
      setTimeout(() => {
        startQuiz(newQuizFromPodium)
      }, 1000)
      setNewQuizFromPodium(null)
    }
  }, [newQuizFromPodium])

  useEffect(() => {
    const manageMessageSocket = async () => {
      if (messageSocket) {
        const objSocket = messageSocket

        if (objSocket.quizGuid) {
          setQuizGuid(objSocket.quizGuid)
        }

        if (objSocket.gameGuid) {
          setGameGuid(objSocket.gameGuid)
        }

        switch (objSocket.event) {
          case 'start':
            if (statusPractice.phase !== statusMapPractice.IN_PODIUM) {
              await startQuiz(objSocket)
            } else {
              // Reset de estados
              onCloseTeacherPractice()

              // Lanzar nuevo Quiz
              if (objSocket.quizGuid) setQuizGuid(objSocket.quizGuid)
              if (objSocket.gameGuid) setGameGuid(objSocket.gameGuid)
              setNewQuizFromPodium(objSocket)
            }

            break

          case 'abort':
            if (!insideLobby && !insidePractice) {
              onCloseTeacherPractice()
              handleExitTeacherPractice()
            }
            break

          default:
            break
        }
      }
    }

    manageMessageSocket()
  }, [messageSocket])

  useEffect(() => {
    checkDemoUser()
  }, [])

  const checkDemoUser = async () => {
    const playerWhoAmI = await getWhoAmI()
    if (playerWhoAmI && playerWhoAmI.is_demo) {
      setUserIsDemo(playerWhoAmI.is_demo)
    }
  }

  const onCloseModalTeacherPractice = async () => {
    Analysis.sendSegmentTrackEvent(Analysis.EVENT.MISSION_POP_UP_OPENED, {
      practice_id: quizGuid,
      is_practice: true
    })

    let playersApi = await getQuizCourseUsers(contentQuiz.course_guid)
    const teachersApi = await getQuizCourseUsersTeachers(
      contentQuiz.course_guid
    )

    // Fusionar playerTeacher con playersApi
    if (teachersApi && teachersApi.length > 0) {
      playersApi = [...playersApi, ...teachersApi]
    }

    setDataPlayersTeacherPracticeStart(playersApi)

    const settings = await getStoredSettings()
    setUserAvatarImg(settings.blueberry?.avatar?.navigation_icon_image)

    joinGame(gameGuid, quizGuid, playersApi?.length)

    goFullScreen()
  }

  const onStartCountDown = () => {
    timer.current = setInterval(() => {
      setCountDownTeacherPractice((prevCounter) => {
        if (prevCounter > 0) {
          setKeyCountDown(Math.random())
          return prevCounter - 1
        } else {
          clearInterval(timer.current)
          setKeyCountDown(Math.random())
          setInsidePractice(true)
          setTimeout(() => {
            setInsideLobby(false)
          }, 300)
          setStatusPractice({ phase: statusMapPractice.IN_QUESTION })
          return prevCounter // return the unchanged value
        }
      })
    }, 1000)
  }

  const exitFullScreen = () => {
    if (isFullscreen) {
      setIsFullscreen(false)
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        /* Check if in fullscreen mode */
        /* Then try to exit */
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari & Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    }
  }

  const sendAnswerData = async (resultAnswer, lemonadeResponseData) => {
    const timeInMs = (secondsToAnswer - timerQuestion) * 1000 // Tiempo que tardó en responder en milisegundos

    if (resultAnswer === 1) {
      setNumberCorrectQuestion(numberCorrectQuestion + 1)
    }

    await sendAnswerQuiz(
      quizGuid,
      contentQuiz.questions[numberQuestion].guid,
      gameGuid,
      timeInMs,
      resultAnswer,
      JSON.stringify(lemonadeResponseData)
    )

    let playerGuid = null
    const aloneMemberData = getAloneUserData()
    if (aloneMemberData) {
      playerGuid = aloneMemberData?.guid
    } else {
      const currentPlayerData = getFamilyUserData()
      playerGuid = currentPlayerData?.guid
    }

    sendAnswer(gameGuid, playerGuid, resultAnswer, timeInMs)
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60

    return `${minutes >= 10 ? minutes : '0' + minutes}:${
      seconds >= 10 ? seconds : '0' + seconds
    }`
  }
  const onCloseTeacherPractice = () => {
    exitFullScreen()
    setIsCreated(false)
    setInsideLobby(false)
    setInsidePractice(false)
    setQuizGuid('')
    setGameGuid('')
    setStatusPractice({ phase: '' })
    setShowModalExitPractice(false)
    setCountDownTeacherPractice(null)
    setNumberQuestion(null)
    setNumberCorrectQuestion(0)
    setIsTeacherDisconnected(false)
    setIsDeletePractice(false)
    setFinishedTimeQuestion(false)
  }

  const onActivityEventQuiz = (event) => {
    ocLog('onActivityEventQuiz', event)
    switch (event.name) {
      case 'Check Answer Quiz': {
        sendAnswerData(
          event.properties.resultAnswer,
          event.properties.lemonadeResponseData
        )
        break
      }

      case 'Sound Quiz Send Answer': {
        playSoundEffect('buttonHoverQuizResult')
        break
      }

      case 'Sound Quiz Correct Answer': {
        playSoundEffect('powerUpQuiz')
        break
      }

      case 'Sound Quiz Error Answer': {
        playSoundEffect('powerDownQuiz')
        break
      }

      case 'Analisys Practice Activity Answered Quiz': {
        Analysis.sendSegmentTrackEvent(
          Analysis.EVENT.PRACTICE_ACTIVITY_ANSWERED,
          {
            seed_id: contentQuiz.questions[numberQuestion].seed_guid
              ? contentQuiz.questions[numberQuestion].seed_guid
              : null,
            learningOutcomeId: contentQuiz.questions[numberQuestion].node_guid
              ? contentQuiz.questions[numberQuestion].node_guid
              : null,
            is_answered: event.properties.is_answered,
            result: event.properties.result
          }
        )
        break
      }

      case 'Analisys Practice Activity Viewed Quiz': {
        Analysis.sendSegmentTrackEvent(
          Analysis.EVENT.PRACTICE_ACTIVITY_ANSWERED,
          {
            seed_id: contentQuiz.questions[numberQuestion].seed_guid
              ? contentQuiz.questions[numberQuestion].seed_guid
              : null,
            learningOutcomeId: contentQuiz.questions[numberQuestion].node_guid
              ? contentQuiz.questions[numberQuestion].node_guid
              : null,
            time_countdown: timerQuestion
          }
        )
        break
      }
    }
  }

  useEffect(() => {
    if (
      statusPractice.phase === statusMapPractice.IN_QUESTION &&
      timerQuestion === 0
    ) {
      // setFinishedTimeQuestion(true)
    }
  }, [timerQuestion])

  return {
    dataPlayersTeacherPractice,
    setDataPlayersTeacherPracticeStart,
    isCreatedTeacherPractice: isCreated,
    isInsideLobbyTeacherPractice: insideLobby,
    totalPlayersTeacherPractice: totalPlayers,
    conectedPlayersTeacherPractice: conectedPlayers,
    isInsidePracticeTeacherPractice: insidePractice,
    contentQuiz,
    setInsideLobby,
    setInsidePractice,
    keyCountDown,
    countDownTeacherPractice,
    gameStartedHandle,
    onCloseModalTeacherPractice,
    statusMapPractice,
    setStatusPractice,
    statusPractice,
    numberQuestion,
    setNumberQuestion,
    numberCorrectQuestion,
    showSolution,
    setShowSolution,
    sendAnswerData,
    formatTime,
    timerQuestion,
    showModalExitPractice,
    setShowModalExitPractice,
    onCloseTeacherPractice,
    userAvatarImg,
    setUserAvatarImg,
    isDeletePractice,
    goFullScreen,
    exitFullScreen,
    isFullscreen,
    isPlayerJoinGameStarted,
    langQuiz: contentQuiz?.lang_id,
    userIsDemo,
    forcedQuizExit,
    setForcedQuizExit,
    onActivityEventQuiz,
    finishedTimeQuestion
  }
}
